@media   (max-width: 768px){
    .mission-container {
        display: flex;
        flex-direction: column;
        justify-content:center ;
        align-items:center ;
        box-shadow: 2px 2px 6px #0B8BBB;
        width: 100%;
        padding: 1px;
        gap: 5px;
        
    
    }
    .mission-content {
        max-width: 500px;
        text-align: center;
        font-size: 8px;
        color: #000000;
        font-weight: 500;
        text-align: left;
    }
    .mission-header-2nd{
        display: none;
    }
    .mission-header {
        font-size: 20px;
        color: #0B8BBB;
        margin-bottom: 5px;
        text-align: center;
        font-family: 'Asul', serif;
        font-weight: 500;
    
    }
    .movingDown {
        animation: moveDownAndFade 0s infinite;
    }
    
    .movingUp {
        animation: moveUpAndFade 0s infinite;
    }
    
    .enlarging {
        animation: enlarge 0s infinite;
    }
    .Aboutus-all-three{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 7%;

    }
    .AboutUS-Detailing-1stframe {
        /* Styles specific to this new div, such as padding or text alignment */
        text-align: left; 
        padding: 5px;
        box-shadow: 2px 2px 6px #0B8BBB;
        width: 200px;
        margin-left: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
    
    .AboutUS-Detailing-1stframe img {
        /* Styles for the SVG image, e.g., width or margin adjustments */
        width: 50px;  /* adjust as needed */
        margin-bottom: 0px; /* spacing between image and header */
        
        margin-top: 0px;
       
    }
    
    .AboutUS-Detailing-1stframe h2 {
        /* Styles for the h2 header */
        font-size: 12px; 
        margin-bottom: 10px;  /* spacing between header and description */
        
        color: #0B8BBB;
    }
    
    .AboutUS-Detailing-1stframe p {
        /* Styles for the description */
        font-size: 10px; 
        font-weight: bold;
        
        color: #EDC12D;
        font-weight: 900;
    }
    /* 2nd card */
    .AboutUS-Detailing-2ndframe {
        /* Styles specific to this new div, such as padding or text alignment */
        text-align: left; 
        padding: 5px;
        box-shadow: 2px 2px 6px #0B8BBB;
        width: 200px;
        margin-left: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
    
    .AboutUS-Detailing-2ndframe img {
        /* Styles for the SVG image, e.g., width or margin adjustments */
        width: 50px;  /* adjust as needed */
        margin-bottom: 0px; /* spacing between image and header */
        
        margin-top: 0px;
       
    }
    
    .AboutUS-Detailing-2ndframe h2 {
        /* Styles for the h2 header */
        font-size: 12px; 
        margin-bottom: 0px;  /* spacing between header and description */
        
        color: #0B8BBB;
    }
    
    .AboutUS-Detailing-2ndframe p {
        /* Styles for the description */
        font-size: 10px; 
        font-weight: bold;
        
        color: #EDC12D;
        font-weight: 900;
    }
      /* 23rd card */
      .AboutUS-Detailing-3rdframe {
        /* Styles specific to this new div, such as padding or text alignment */
        text-align: left; 
        padding: 5px;
        box-shadow: 2px 2px 6px #0B8BBB;
        width: 200px;
        margin-left: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
    
   
    
    .AboutUS-Detailing-3rdframe h2 {
        /* Styles for the h2 header */
        font-size: 12px; 
        margin-bottom: 0px;  /* spacing between header and description */
        
        color: #0B8BBB;
    }
    
    .AboutUS-Detailing-3rdframe p {
        /* Styles for the description */
        font-size: 10px; 
        font-weight: bold;
        
        color: #EDC12D;
        font-weight: 900;
    }
    .AboutUS-Detailing-3rdframe img {
        /* Styles for the SVG image, e.g., width or margin adjustments */
        width: 50%;  /* adjust as needed */
        margin-bottom: 0px; /* spacing between image and header */
        margin-left: 25%;
        margin-top: 0px;
       
    }
    
    
    
}
@media screen and (min-width: 769px) and (max-width: 1025px) {
  
    .mission-header-2nd{
        color: transparent;
        -webkit-text-stroke: 1px #0B8BBB; /* Adjust the px value for stroke thickness */
        text-stroke: 2px black; /* For browsers that might support it without prefix in the future */
        font-weight: 990; /* Optional, but can make the outline look better */
        font-size: 50px;
        font-family: 'Asul', serif;
        text-align: center;
    }
}
