body {
    margin: 0;
    padding: 0px;
    font-family: "Arial", sans-serif;
    overflow: hidden; 
  }
  
  .scroll-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; 
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .section {
    height: 100vh;
    scroll-snap-align: start;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  .pagination {
    position: fixed;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
  }
  
  .pagination-dot {
    width: 10px;
    height: 10px;
    background: #6e6e6e;
    border-radius: 50%;
    transition: background 0.3s;
    cursor: pointer;
  }
  
  .pagination-dot:hover {
    background: #555;
  }
  
  .pagination-dot.active {
    background: gold;
  }
  
  
  /* styling start here */
  
  
  .Resources-container {
    width: 100%;
    max-width: 1200px; 
    margin: 1px auto; /* 'auto' added for horizontal centering */
    flex-wrap: wrap;
    text-align: center;
  
    
    align-items: center; /* ensures the content within the container is centered */
    display: flex;
    justify-content: space-between; /* This will evenly distribute the cards */
    flex-wrap: wrap;  /* ensures content starts at the top */
}

.Resources-heading {
    font-size: 2.5em;
    color: #2C3E50;
    font-weight: bold;
    margin-bottom: 30px;
    border-bottom: 2px solid #E8BB2C;
    padding-bottom: 10px;
    align-self: center; /* ensures the heading itself is centered within the flex container */
    margin-left: 60px;
}

.Resources-card {
    width: 32%; /* Sets each card to 30% of the container's width */
    height: 450px; 
    background-color: #D6D6D6;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Adjusted the margin to provide vertical spacing between cards if they wrap to the next line */
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  padding: 4%;
  
}

.Resources-card:hover {
    transform: scale(1.05); /* Enlarges the card by 5% */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Adds a deeper shadow on hover */
}


.Resources-card-image {
    width: auto; /* makes sure the image takes the full width of the card */
    height: 100%; /* adjust based on your design needs */
    object-fit: cover; /* ensures the aspect ratio of the image is maintained */
    border-radius: 6px; /* optional, to give the image rounded corners */
    margin-top: 0px; /* space between the heading and the image */
}

.Resources-download-btn {
    display: block;
    background-color: #E6B72A; /* Dark blue to match your theme */
    color: #000000; /* White text */
    padding: 10px 15px;
    margin-top: 10px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
    
    

}

.Resources-download-btn:hover {
    background-color: #000000;
    color: #E6B72A; /* Color transition on hover to the golden color */
}

.Resources-cardTwo {
    width: 30%;
    margin: 1.5%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

.Resources-cardTwo:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.Resources-cardTwo-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
    border-radius: 5px;
}
.Resource-ebookCard{
  display: flex;
  justify-content: space-evenly;
  

}
.Resources-cardTwo-downloadBtn {
    display: inline-block;
    padding: 10px 20px;
    color: #000000;
    background-color: #E8BB2C;
    text-align: center;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    font-weight: 900;
    border-radius: 5px;
   
}

.Resources-cardTwo-downloadBtn:hover {
    background-color: #000000;
    color:#E8BB2C ;
}
@media screen and (max-width: 768px) {
  .Resources-container, .Resource-ebookCard, .Resources-card, .Resources-cardTwo {
    display: none; /* Hide desktop resources */
}

.mobile-resources-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.mobile-resource-image {
    max-width: 90%;
    margin-bottom: 20px;
}

.mobile-navigation-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobile-prev-btn, .mobile-next-btn {
    padding: 10px 20px;
    background-color: #E6B72A;
    color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: auto;
}
.mobile-download-btn {
  padding: 10px 20px;
  margin-bottom: 20px; /* Spacing below the button */
  background-color: #E6B72A;
  color: #000000;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mobile-download-btn:hover {
  background-color: #000000;
  color: #E6B72A;
}
}
  
  
  
  
  
  