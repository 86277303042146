html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  overflow: hidden; 
}

.scroll-container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none; 
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.section {
  height: 100vh;
  scroll-snap-align: start;
}

.pagination {
  position: fixed;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.pagination-dot {
  width: 10px;
  height: 10px;
  background: #6e6e6e;
  border-radius: 50%;
  transition: background 0.3s;
  cursor: pointer;
}

.pagination-dot:hover {
  background: #555;
}

.pagination-dot.active {
  background: gold;
}


/* starting */
.BusinessConsultancy-heading {
  text-align: center;
  margin-top: 40vh; /* Vertically centering it in the viewport */
  font-size: 3rem;
  color: #0B8BBB;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #E8BB2C;
  width: 100%;
  outline: 2px solid white; /* Adjust thickness if needed */
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
}
.BusinessConsultancy-contentFlex {
  display: flex;
  justify-content: center;
  align-items: center; /* To space out the heading and video */
  gap: 50px ;
  
 
   /* Optional padding for spacing */
}

.BusinessConsultancy-video {
  width: 40vw; /* You can adjust this based on your design preference */
  height: 50vh;
  align-items: center;
  margin-top: 70px;
  border-radius: 10px;
}
.BusinessConsultancy-video:hover{
  box-shadow: 2px 4px 6px #0B8BBB;
}


.IFRAME_VIDEO_BUSINESS_CONSULTANCY{
  margin-top: 5%;
  border-radius: 5%;
  width: 40%;
  height: 40vh;
}

@keyframes drawTextAchieve {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fillTextAchieve {
  from {
    fill-opacity: 0;
  }
  to {
    fill-opacity: 1;
  }
}
.BusinessAchievesvgPath {
  stroke: #E8BB2C; /* Color of ACHIEVE */
  fill: #E8BB2C; 
  fill-opacity: 0; 
  stroke-width: 2; 
  stroke-dasharray: 1000; 
  stroke-dashoffset: 1000; 
  animation: drawTextAchieve 8s forwards, fillTextAchieve 1s forwards 3s  ; 
}

@keyframes drawTextMore {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fillTextMore {
  from {
    fill-opacity: 0;
  }
  to {
    fill-opacity: 1;
  }
}
.BusinessMoresvgPath {
  stroke: #0B8BBB; /* Color of ACHIEVE */
  fill: #0B8BBB; 
  fill-opacity: 0; 
  stroke-width: 2; 
  stroke-dasharray: 1000; 
  stroke-dashoffset: 1000; 
  animation: drawTextAchieve 8s forwards , fillTextAchieve 1s forwards 3s ; 
}
.BusinessConsultancy-all-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px ;
}
#Layer_2{
  margin-top: 60px;
}
.BusinessConsultancy-Onlinewebinar-2ndframe {
 
  width: 100%;
  max-width: 300px;  /* or whatever maximum width you desire */
  
  display: block;
}
.frame2nd{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5% ;
}
.BusinessConsultancy-subheading-2ndframe{
  text-align: center;
  margin-top: 300px;
  font-size: 30px;
  color: #0B8BBB;
  text-transform: uppercase;
  
}


@keyframes comeFromLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes comeFromRight {
  0% {
      transform: translateX(100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes comeFromBottom {
  0% {
      transform: translateY(100%);
      opacity: 0;
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}
/* ONLINEWEBINAR coming from left */
.BusinessConsultancy-Onlinewebinar-2ndframe:first-of-type {
  animation: comeFromLeft 3s forwards;
}

/* OFFLINEWORKSHOP coming from right */
.BusinessConsultancy-Onlinewebinar-2ndframe:last-of-type {
  animation: comeFromRight 3s forwards;
}

/* H2 coming from bottom */
.BusinessConsultancy-subheading-2ndframe {
  animation: comeFromBottom 3s forwards;
}
.BusinessConsultancy-Onlinewebinar-2ndframe, .BusinessConsultancy-subheading-2ndframe {
  opacity: 0;
}


.BusinessConsultancy-Onlinewebinar-2ndframe:first-of-type {
  animation: comeFromLeft 1s forwards;
  animation-delay: 0.2s;
}

.BusinessConsultancy-Onlinewebinar-2ndframe:last-of-type {
  animation: comeFromRight 1s forwards;
  animation-delay: 0.4s;
}

.BusinessConsultancy-subheading-2ndframe {
  animation: comeFromBottom 1s forwards;
  animation-delay: 0.6s;
}
/* 3rd frame */

.BusinessConsultancyCircleContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;  /* Set to viewport height to center the circle */
  
 

}


.BusinessConsultancyCircle {
  width: 20vw;  /* Adjust as needed */
  height: 30vh; /* Adjust as needed */
  background-color: goldenrod;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.BusinessConsultancyText {
  font-size: 50px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some spacing between the text and the link */
  color: white;
  display: flex; 
  
}

.BusinessConsultancyLink {
  text-decoration: none;
  color: black;
  font-size: 16px; /* Adjust font size as needed */
  position: relative;  /* Set the link's position property to relative */
  transition: all 0.3s;  /* Smooth transition for any changes */
  padding-right: 20px;
  /* Add padding to prevent the text and arrow from being too close */
  
}

.BusinessConsultancyLink::after {
  content: '→';  /* Arrow character */
  position: absolute;
  right: 0;  /* Position it at the right-most part of the link */
  opacity: 0;  /* Start with the arrow hidden */
  transition: all 0.3s;  /* Smooth transition for opacity */
  
}
.BusinessConsultancyLink:hover{
  color: rgb(255, 255, 255);
}
.BusinessConsultancyLink:hover::after {
  opacity: 1;  /* Show the arrow when the link is hovered */

}
.BusinessConsultancyCircleMarketing{
  width: 15vw;  /* Adjust as needed */
  height: 25vh; /* Adjust as needed */
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  margin-top: 150px;
 
}
.BusinessConsultancyTextMarketing{
  font-size: 24px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some spacing between the text and the link */
  color: white;
}
.BusinessConsultancyCircleFinance{
  width: 15vw;  /* Adjust as needed */
  height: 25vh; /* Adjust as needed */
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  margin-top: 150px;
  margin-left: 50px;
}
.BusinessConsultancyTextFinance{
  font-size: 24px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some spacing between the text and the link */
  color: white;
}
.BusinessConsultancyCircleBrand{
  position: absolute;
  width: 15vw;
  height: 25vh;
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  top: 15%;
  left: 25%;

}
.BusinessConsultancyTextBrand{
  font-size: 24px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some spacing between the text and the link */
  color: white;
}

.BusinessConsultancyCircleAI{
  position: absolute;
  width: 15vw;  /* Adjust as needed */
  height: 25vh; /* Adjust as needed */
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
top: 15%;
left: 62%;
 
}
.BusinessConsultancyTextAI{
  font-size: 24px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some spacing between the text and the link */
  color: white;
}
.BusinessConsultancyCirclesales{
  position: absolute;
  width: 15vw;  /* Adjust as needed */
  height: 25vh; /* Adjust as needed */
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
top: 7%;
left: 44%;

 
}


.BusinessConsultancyTextsales{
  font-size: 22px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some spacing between the text and the link */
  color: white;
}
.BusinessConsultancy-video{
  height: 50vh;
 width: auto;
 
}