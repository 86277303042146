.Thought-of-day {
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 1000px;
   
    font-family: 'Arial', sans-serif;
    background-color: #0B8BBB;
    
}

.Thought-of-day h2 {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
    color: gold;
    font-size: 30px;
}

.Thought-of-day p {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
}
@media   (max-width: 768px){
    .Thought-of-day {
        border: 2px solid #f0f0f0;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        width: 100vw;
        margin-top: 1%;
        font-family: 'Poppins', sans-serif;
        background-color: #0B8BBB;
        
    }
    .Thought-of-day h2 {
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 2%;
        margin-bottom: 1%;
        text-align: center;
        font-weight: bold;
        color: gold;
        font-size: 20px;
    }
    .Thought-of-day p {
        font-size: 15px;
        text-align: center;
        color: rgb(255, 255, 255);
    }
}