@media   (max-width: 768px){
    /* 1stframe */
    .Insights-cardContainer {
        display: flex;
        flex-direction: column;
        
      }
      .Insights-cardFirst {
        width: 55vw;
        height: 30vh;
        background-color: #0B8BBB;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        margin-left: 25%;
        margin-top: 2%;
        margin-bottom: 5%;
        
      }
      .Insights-cardImage {
        max-width: 90%;  /* Ensure the image doesn't overflow the card */
        height: auto;    /* Maintain the image's aspect ratio */
        display: block;  /* Remove any alignment issues */
        margin: 10px auto;
        margin-top: 0px;
        border-radius: 3px;
        
      }
      .Insights-cardFirst h2, .Insights-cardSecond h2, .Insights-cardThird h2 {
  
        color: #ffffff;  /* Set text color to white for better readability on the blue background */
        font-size: 10px;  /* Adjust size if needed */
        text-align: center;  /* Center align the text */
        padding: 1%;  /* Space around the heading */
        /* Add any other desired styles */
      }
      .Insights-clickableText {
        display: inline-block;
        margin-top: 0px;
        padding: 5px 8px;
        background-color: #ffffff;
        color: #0B8BBB;  /* To make the text white */
        font-weight: 900;
        border-radius: 8px;
        text-decoration: none;  /* Remove underline */
        font-size: 0.5rem;  /* Slightly larger font */
        transition: all 0.2s; /* Smooth transition */
        position: relative;  /* To position the arrow inside it on hover */
        
      }
      /* 2ndcard */
      .Insights-cardSecond {
        width: 45vw;
        height: 30vh;
        background-color: #0B8BBB;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        margin-left: 20px;
        margin-top: 10px;
        
      }
      .Insights-clickableTextSecond {
        display: inline-block;
        margin-top: 0px;
        padding: 5px 8px;
        background-color: #ffffff;
        color: #0B8BBB;  /* To make the text white */
        font-weight: 900;
        border-radius: 8px;
        text-decoration: none;  /* Remove underline */
        font-size: 0.5rem;  /* Slightly larger font */
        transition: all 0.2s; /* Smooth transition */
        position: relative;  /* To position the arrow inside it on hover */
        
      }
      #InsightssecondcardIMG{
        height: 10vh;
        width: auto;
      }
      /* 3rd */
      .Insights-cardThird {
        width: 45vw;
        height: 30vh;
        background-color: #0B8BBB;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        margin-left: 20px;
        margin-top: 10px;
        
      }
      .Insights-clickableTextThird {
        display: inline-block;
        margin-top: 0px;
        padding: 5px 8px;
        background-color: #ffffff;
        color: #0B8BBB;  /* To make the text white */
        font-weight: 900;
        border-radius: 8px;
        text-decoration: none;  /* Remove underline */
        font-size: 0.5rem;  /* Slightly larger font */
        transition: all 0.2s; /* Smooth transition */
        position: relative;  /* To position the arrow inside it on hover */
        
      }
    }
    @media screen and (min-width: 769px) and (max-width: 1025px) {
      .Insights-cardFirst {
        width: 60vw;
        height: auto;
        background-color: #0B8BBB;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        margin-left: 20px;
        margin-top: 0%;
       
        
      }
      .Insights-cardSecond   {
        width: 40vw;
        height: 38vh;
        background-color: #0B8BBB;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        
        margin-left: 1%;
        margin-top: 1%;
        
      }
      .Insights-cardThird {
        width: 40vw;
        height: 38vh;
        background-color: #0B8BBB;
        border-radius: 8px;
        padding: 16px;
        text-align: center;
        margin-right: 20px;
        margin-top: 1%;
        
      }
      .Insights-FirstcardImage {
  max-width: 100%;  /* Ensure the image doesn't overflow the card */
  
  height: auto;    /* Maintain the image's aspect ratio */
  display: block;  /* Remove any alignment issues */
  margin: 10px auto;
  margin-top: 30px;
  border-radius: 3px;
  
}
.Insights-cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  
}
.Insights-clickableText {
  display: inline-block;
  margin-top: 2%;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #0B8BBB;  /* To make the text white */
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;  /* Remove underline */
  font-size: 1.2rem;  /* Slightly larger font */
  transition: all 0.2s; /* Smooth transition */
  position: relative;  /* To position the arrow inside it on hover */
  
}
#InsightssecondcardIMG{
  height: 50%;
}
.Insights-clickableTextSecond {
  display: inline-block;
  margin-top: 1%;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #0B8BBB;
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: all 0.2s;
  position: relative;
}
.Insights-clickableTextThird {
  display: inline-block;
  margin-top: 5%;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #0B8BBB;
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: all 0.2s;
  position: relative;
}
    }