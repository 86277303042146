@media screen and (max-width: 768px) {

    .navbar {
        display: none;
    }
    .mobile-nav {
        display: block;
        margin-left: 25%;
    }
    .company-logo {
      height: 7vh;  
      width: auto;
  }
  .company-slogan {
    font-size: 1.1rem;
    margin: 0; 
    font-weight: 700;
   display: flex;
   gap: 5%;
   margin-left: 20%;
    
}
.coding-visuals {
  position: absolute;
  bottom: 0; /* Align with the bottom of the container */
  left: 0;
  transform: translateY(100%); /* Initially moved down out of view */
  opacity: 0; /* Make it fully transparent */
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  /* Additional base styles for the text */
  margin-top: 10%;
  color: black;
  font-size: 0.6rem;
  font-weight: 900;
  text-transform: uppercase;
  background-color: rgb(255, 255, 255);
  width: max-content;
  padding: 0%;
}
.logo-container:hover .coding-visuals {
  transform: translateY(-55%); /* Bring text up to the bottom of the logo */
  opacity: 1; /* Make text fully visible */
  
}

}
/* ... existing styles ... */
@media screen and (min-width: 769px) and (max-width: 1024px) {
 .navbar{
  display: none;
 }
 .mobile-nav{
    margin-left: 25%;
 }
 .company-slogan{
  margin-left: 20%;
 }
 .company-logo{
  margin-left: 45%;
 }
 .coding-visuals {
  position: absolute;
  bottom: 0; /* Align with the bottom of the container */
  left: 0;
  transform: translateY(100%); /* Initially moved down out of view */
  opacity: 0; /* Make it fully transparent */
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  /* Additional base styles for the text */
  margin-top: 10%;
  color: black;
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  background-color: black;
  width: max-content;
  padding: 0%;
}
.logo-container:hover .coding-visuals {
  transform: translateY(-55%); /* Bring text up to the bottom of the logo */
  opacity: 1; /* Make text fully visible */
  
}
.logo-container:hover .company-logo {
  transform: scale(0.5) translate(50%, -40%); 
}

}
/* Hide hamburger menu for desktop screens */
@media screen and (min-width: 1025px ) {
  .mobile-nav {
      display: none;
  }
 
}

