@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.OurPlans-container {
    text-align: center;
    padding: 10px;
    perspective: 1500px; 
  }
  
  .OurPlans-heading {
    font-size: 3em;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  
  .OurPlans-cardContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .OurPlans-card {
    width: 30%;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    position: relative;
    margin-top: 3.5%;
    
  }
  .OurPlans-card a{
    text-decoration: none;
  }
  
  .OurPlans-card:hover {
    transform: translateY(-10px);
  }
  
  .OurPlans-cardTitle {
    font-size: 2em;
    margin-bottom: 15px;
    text-transform: uppercase;
    border: 2px solid;
    background-color: #0B8BBB;
    color: white;
  }
  .OurPlans-feature{
    text-align: left;
  }
    
  .OurPlans-overlay {
    position: absolute;
    top: -17%;
    left: 0;
    width: 100%;
    padding: 10px 0;
    background: linear-gradient(to bottom, #E7B82B 0%, rgba(0,0,0,0) 100%);
    color: #000000;
    text-align: center;
    border-top-left-radius: 10px; /* Match with card border radius */
    border-top-right-radius: 10px; /* Match with card border radius */
  }
  
  .OurPlans-overlayText {
    display: inline-block;
    padding: 3px 12px;
    background: #E8BB2C; /* A vibrant color to grab attention */
    border-radius: 5px;
    font-size: 0.9em;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    margin-top: 10px; /* Adjust as needed for positioning */
    
  }
  
  .OurPlans-price {
    font-size: 1.8em; /* Adjust size as needed */
    font-weight: bold;
    color: #ffffff; /* Or any other color that fits your brand */
    margin-top: 10%; /* Space above the price */
    padding: 5px 0; /* Padding for visual comfort */
    background: #03BAA8; /* Subtle background, can be adjusted */
    border-radius: 5px; /* Soften the corners */
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Slight shadow for depth */
    cursor: pointer;
    position: relative;
  }
  .OurPlans-price:hover{
    color: white;
    background-color: #0B8BBB;
  }
  .OurPlans-hoverMessage {
    display: none;
    position: absolute;
    bottom: -65%; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
    font-size: 0.5em;
  }
  
  .OurPlans-price:hover .OurPlans-hoverMessage {
    display: block;
  }
  .Bharat{
    display: none;
  }
  .OurPlans-heading {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 20px;
}
.OurPlans-service {
  color: #000000; /* Set text color to white */
  border: 2px solid #000000; /* White border */
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px; /* Rounded corners for the border */
  background: rgb(232, 187, 45, 0.6); /* Semi-transparent black background for depth */
  box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.2); /* White shadow for depth */
  text-align: center; /* Aligning the text to the left */
display: flex;
justify-content: space-between;
width: 100%;

}

.OurPlans-gradient-bg {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  color: #000; /* Adjust text color as needed to contrast with the image */
  width: 100%;
  height: auto;
display: flex;
flex-direction: column;

align-items: center;
}
.Ourplans-2nd-page{
  background-color: #088BBB;
  width: fit-content;
  color: white;
  padding: 0.5%;
  
}
.OurPlans-gradient-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.5; /* Adjust for desired opacity */
  z-index: -1; /* Ensures the content is above the background image */
}
.OurPlans-service-button {
  background-color: #ffffff; /* White background */
  color: #000000; /* Initial text color */
  border: 2px solid #000000; /* Initial border color */
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none; /* Removes underline */
  display: inline-block; /* Ensures proper button formatting */
  position: relative; /* Required for absolute positioning of the pseudo-element */
  overflow: hidden; /* Ensures the sliding effect is contained within the button boundaries */
  transition: color 0.3s ease; /* Smooth transition for text color */
}

.OurPlans-service-button::before {
  content: '';
  position: absolute;
  top: 0;
  right: -100%; /* Start from the right */
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to left, #E8BB2D, #088BBB); /* Blue to red gradient */
  border-radius: 5px; /* Match the button's border-radius */
  transition: right 0.3s ease; /* Animation for the slide-in effect */
  z-index: 0;
}

.OurPlans-service-button:hover::before {
  right: 100%; /* Slide across the button to the left */
}

.OurPlans-service-button:hover {

  
  color: #088BBB;
}

  @media   (max-width: 768px) {
    .OurPlans-heading {
      font-size: 1.5rem;
      margin-bottom: 1%;
      text-transform: uppercase;
      
    }
    .scroll-container {
      width: 90%;
      height: 100vh;
      overflow-y: scroll;
      scroll-snap-type: y mandatory;
      scrollbar-width: none; 
    }
    
    .OurPlans-cardTitle {
      font-size: 1.5rem;
      margin-bottom: 15px;
      text-transform: uppercase;
      border: 2px solid;
      background-color: #0B8BBB;
      color: white;
    }
    .slick-track{
      width: 90%;
    }
    .OurPlans-1stcard {
      width: 30%;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 5px;
      box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
      transition: transform 0.3s ease;
      position: relative;
      margin-top: 2%;
      
    }
    .OurPlans-feature{
      text-align: left;
      font-size: 1rem;
    }
    .OurPlans-cardContainer {
      display: none;
    }
    .Bharat{
      display: block;
      box-sizing: border-box;
    }
    .OurPlans-cardTitle{
      text-align: center;
    }
    .OurPlans-price{
      text-align: center;
    }
    .OurPlans-overlay {
      position: absolute;
      top: -7%;
      left: 0;
      width: 100%;
      padding: 10px 0;
      background: linear-gradient(to bottom, #E7B82B 0%, rgba(0,0,0,0) 100%);
      color: #000000;
      text-align: center;
      border-top-left-radius: 10px; /* Match with card border radius */
      border-top-right-radius: 10px; /* Match with card border radius */
   
    }
    
    .OurPlans-overlayText {
      display: inline-block;
      padding: 1px 5px;
      background: #E8BB2C; /* A vibrant color to grab attention */
      border-radius: 5px;
      font-size: 0.7em;
      font-weight: bold;
      text-transform: uppercase;
      box-shadow: 0 2px 4px rgba(0,0,0,0.5);
      margin-top: 0px; /* Adjust as needed for positioning */
      
    }
    .OurPlans-hoverMessage {
      display: none;
      position: absolute;
      bottom: -65%; /* Adjust as needed */
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      background-color: #000;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
      font-size: 0.4em;
    }
    .OurPlans-container {
      text-align: center;
      padding: 0px;
      perspective: 1500px; 
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1025px) {
    .OurPlans-cardContainer {
      display: none;
    }
    .Bharat{
      display: block;
    }
    .OurPlans-cardTitle{
      text-align: center;
    }
    .OurPlans-price{
      text-align: center;
    }
    .OurPlans-overlay {
      position: absolute;
      top: -7%;
      left: 0;
      width: 100%;
      padding: 10px 0;
      background: linear-gradient(to bottom, #E7B82B 0%, rgba(0,0,0,0) 100%);
      color: #000000;
      text-align: center;
      border-top-left-radius: 10px; /* Match with card border radius */
      border-top-right-radius: 10px; /* Match with card border radius */
   
    }
    
    .OurPlans-overlayText {
      display: inline-block;
      padding: 3px 12px;
      background: #E8BB2C; /* A vibrant color to grab attention */
      border-radius: 5px;
      font-size: 0.9em;
      font-weight: bold;
      text-transform: uppercase;
      box-shadow: 0 2px 4px rgba(0,0,0,0.5);
      margin-top: 5px; /* Adjust as needed for positioning */
      
    }
    .OurPlans-hoverMessage {
      display: none;
      position: absolute;
      bottom: -65%; /* Adjust as needed */
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      background-color: #000;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
      font-size: 0.4em;
    }
  }