@media   (max-width: 768px) {
    .Solution-FirstCard {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Horizontally center the content */
        gap: 0px;
        width: 100%; 
        /* Take the full width of its parent */
      }
      .Solution-FirstCardHeader {
        font-size: 14px; /* Adjust the font size as needed */
        color: #0B8BBB; /* Adjust the font color as needed */
        margin-top: 0px ; /* Adjust the margin as needed */
        text-align: center; /* Centers the text */
        font-weight: bold;
        /* Add any other desired styling here */
        display: block; /* So it's above the text */
        margin-bottom: 20px;
        
        
        padding: 2px;
        
      }
      .Solution-FirstCardAI {
        position: absolute;
        width: 20vw; 
        border-radius: 8px;
        object-fit: cover;
        height: auto;
        /* Align the image to the top of its container */
        margin-left: 73%;
        margin-top: 15%;
        z-index: 0;
        transition: transform 0.3s, box-shadow 0.3s; 
       z-index: 2000;
      }
      .Solutionrobotsvg{
        height: 1.5rem;
        margin-top: 0px;
      }
      .Solution-card {
        flex: 1; 
        width: 90vw; 
        height: 65vh ;
        box-sizing: border-box;
        padding: 3%;
        margin: 2%;
        background: rgb(255, 255, 255);
        border-radius: 8px;
        position: relative;
        
        
        
      }
      .Solution-FirstCardText {
        font-size: 14px;
        background-color: rgba(255, 255, 255, 0.9);
        transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
        padding: 2%;
        margin-top: 5%;
        flex-basis: 70%; /* Take up half the space */
        width: 75vw;
        z-index: 1;
      }
      .SolutionFIRSTFRAMEAIBUTTON {
        position: absolute;
        top: 82%;
        right: 7%;
        display: inline-flex;
        margin-left: 5%;
        align-items: center;
        padding: 2px 5px;
        text-decoration: none;
        color: #000000;
        font-size: 12px;
        font-weight: 900;
        cursor: pointer;
        background-color: #E8BB2C; /* Light gray background */
        border: 2px solid #ddd; /* Slightly darker gray for the border */
        border-radius: 25px;
        transition: all 0.3s;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
       
      }
      .Solution-icon {
        width: 5vw;
        height:auto;
        margin-left: 0px;
        fill: #0B8BBB;  /* Adjust as needed */
      }
      .Solution-modal-content {
        background-color: #fefefe;
        
        margin-top: 55% ;
        padding: 10px;
        border: 1px solid #888;
        width: 60vw;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        
      }
      .Solution-contact-option a{
        text-decoration: none;
        color: #0B8BBB;
        font-size: 12px;
      }
      /* 2ndframe */
      .Solution-SecondCard {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Horizontally center the content */
        gap: 0px;
        width: 100%; 
        /* Take the full width of its parent */
      }
      .Solution-SecondCardHeader {
        font-size: 14px; /* Adjust the font size as needed */
        color: #0B8BBB; /* Adjust the font color as needed */
        margin-top: 0px ; /* Adjust the margin as needed */
        text-align: center; /* Centers the text */
        font-weight: bold;
        /* Add any other desired styling here */
        display: block; /* So it's above the text */
        margin-bottom: 20px;
        margin-left: 5%;
        
        padding: 2px;
        
      }
      .Solution-SecondCardAI {
        position: absolute;
        width: 20vw; 
        border-radius: 8px;
        object-fit: cover;
        height: auto;
        /* Align the image to the top of its container */
        margin-left: 73%;
        margin-top: 15%;
        z-index: 0;
        transition: transform 0.3s, box-shadow 0.3s; 
       
      }
      .Solution-SecondCardAI{
        width: 20vw;
        height: auto;
        margin-top: 0px;
        position: absolute;
        top: 7%;
        right: 1%;
        z-index: 400;
      }
      .Solutionranking-starsvg {
        position: absolute;
        height: 1.5rem;
        
        object-fit: cover;
       
        /* Align the image to the top of its container */
        margin-left: 0;
        
        margin-top: 0;
        z-index: 0;
        transition: transform 0.3s, box-shadow 0.3s; 
       
      }
      .Solution-SecondCardText {
        font-size: 14px;
        background-color: rgba(255, 255, 255, 0.9);
        transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
        padding: 2%;
        margin-top: 15%;
        flex-basis: 70%; /* Take up half the space */
        width: 75vw;
        z-index: 1;
      }
      .SolutionSECONDFRAMEBUSINESSBUTTON {
        position: absolute;
        top: 88%;
        right: 7%;
        display: inline-flex;
        margin-left: 5%;
        align-items: center;
        padding: 2px 5px;
        text-decoration: none;
        color: #000000;
        font-size: 12px;
        font-weight: 900;
        cursor: pointer;
        background-color: #E8BB2C; /* Light gray background */
        border: 2px solid #ddd; /* Slightly darker gray for the border */
        border-radius: 25px;
        transition: all 0.3s;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
       
      }
      /* 3rdframe */
      .Solution-ThirdCard {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Horizontally center the content */
        gap: 0px;
        width: 100%; 
        /* Take the full width of its parent */
      }
      .Solution-ThirdCardHeader {
        font-size: 14px; /* Adjust the font size as needed */
        color: #0B8BBB; /* Adjust the font color as needed */
        margin-top: 0px ; /* Adjust the margin as needed */
        text-align: center; /* Centers the text */
        font-weight: bold;
        /* Add any other desired styling here */
        display: block; /* So it's above the text */
        margin-bottom: 20px;
        margin-left: 5%;
        
        padding: 2px;
        
      }
      .Solution-ThirdCardAI {
        position: absolute;
        width: 20vw; 
        border-radius: 8px;
        object-fit: cover;
        height: auto;
        /* Align the image to the top of its container */
        margin-left: 73%;
        margin-top: 15%;
        z-index: 0;
        transition: transform 0.3s, box-shadow 0.3s; 
       
      }
      .Solution-ThirdCardAI{
        width: 20vw;
        height: auto;
        margin-top: 0px;
        position: absolute;
        top: 6%;
        right: 1%;
        z-index: 400;
      }
      .Solutiondollarbagsvg {
        position: absolute;
        height: 1.5rem;
        
        object-fit: cover;
       
        /* Align the image to the top of its container */
        margin-left: 2.5%;
        
        margin-top: 0%;
        z-index: 0;
        transition: transform 0.3s, box-shadow 0.3s; 
       
      }
      .Solution-ThirdCardText {
        font-size: 13px;
        background-color: rgba(255, 255, 255, 0.9);
        transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
        padding: 2%;
        margin-top: 5%;
        flex-basis: 70%; /* Take up half the space */
        width: 75vw;
        z-index: 1;
      }
      .SolutionTHIRDFRAMEFINANCEBUTTON {
        position: absolute;
        top: 88%;
        right: 7%;
        display: inline-flex;
        margin-left: 5%;
        align-items: center;
        padding: 2px 5px;
        text-decoration: none;
        color: #000000;
        font-size: 10px;
        font-weight: 900;
        cursor: pointer;
        background-color: #E8BB2C; /* Light gray background */
        border: 2px solid #ddd; /* Slightly darker gray for the border */
        border-radius: 25px;
        transition: all 0.3s;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
       
      }
      /* 4thcard */
      .Solution-FourthCard {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; /* Horizontally center the content */
        gap: 0px;
        width: 100%; 
        /* Take the full width of its parent */
      }
      .Solution-FourthCardHeader {
        font-size: 14px; /* Adjust the font size as needed */
        color: #0B8BBB; /* Adjust the font color as needed */
        margin-top: 0px ; /* Adjust the margin as needed */
        text-align: center; /* Centers the text */
        font-weight: bold;
        /* Add any other desired styling here */
        display: block; /* So it's above the text */
        margin-bottom: 20px;
        margin-left: 5%;
        
        padding: 2px;
        
      }
      .Solution-FourthCardAI {
        position: absolute;
        width: 20vw; 
        border-radius: 8px;
        object-fit: cover;
        height: auto;
        /* Align the image to the top of its container */
        margin-left: 73%;
        margin-top: 15%;
        z-index: 0;
        transition: transform 0.3s, box-shadow 0.3s; 
       
      }
      .Solution-FourthCardAI{
        width: 20vw;
        height: auto;
        margin-top: 0px;
        position: absolute;
        top: 6%;
        right: 1%;
        z-index: 400;
      }
      .SolutionBusinessbagsvg {
        position: absolute;
        height: 1.5rem;
        
        object-fit: cover;
       
        /* Align the image to the top of its container */
        margin-left: 1%;
        
        margin-top: 1%;
        z-index: 0;
        transition: transform 0.3s, box-shadow 0.3s; 
       
      }
      .Solution-FourthCardText {
        font-size: 13px;
        background-color: rgba(255, 255, 255, 0.9);
        transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
        padding: 2%;
        margin-top: 5%;
        flex-basis: 70%; /* Take up half the space */
        width: 75vw;
        z-index: 1;
      }
      .SolutionFOURTHFRAMESALESBUTTON{
        position: absolute;
        top: 88%;
        right: 20%;
        display: inline-flex;
        margin-left: 5%;
        align-items: center;
        padding: 2px 5px;
        text-decoration: none;
        color: #000000;
        font-size: 10px;
        font-weight: 900;
        cursor: pointer;
        background-color: #E8BB2C; /* Light gray background */
        border: 2px solid #ddd; /* Slightly darker gray for the border */
        border-radius: 25px;
        transition: all 0.3s;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
       
      }
/* 5thcard */
.Solution-FifthCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Horizontally center the content */
    gap: 0px;
    width: 100%; 
    /* Take the full width of its parent */
  }
  .Solution-FifthCardHeader {
    font-size: 14px; /* Adjust the font size as needed */
    color: #0B8BBB; /* Adjust the font color as needed */
    margin-top: 0px ; /* Adjust the margin as needed */
    text-align: center; /* Centers the text */
    font-weight: bold;
    /* Add any other desired styling here */
    display: block; /* So it's above the text */
    margin-bottom: 20px;
    margin-left: 5%;
    
    padding: 2px;
    
  }
  .Solution-FifthCardAI {
    position: absolute;
    width: 20vw; 
    border-radius: 8px;
    object-fit: cover;
    height: auto;
    /* Align the image to the top of its container */
    margin-left: 73%;
    margin-top: 15%;
    z-index: 0;
    transition: transform 0.3s, box-shadow 0.3s; 
   
  }
  .Solution-FifthCardAI{
    width: 40vw;
    height: auto;
    margin-top: 0px;
    position: absolute;
    top: -1%;
    right: -12%;
    z-index: 400;
  }
  .SolutionCodingsvg {
    position: absolute;
    height: 1.5rem;
    
    object-fit: cover;
   
    /* Align the image to the top of its container */
    margin-left: 0;
    
    margin-top: 1%;
    z-index: 0;
    transition: transform 0.3s, box-shadow 0.3s; 
   
  }
  .Solution-FifthCardText {
    font-size: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
    padding: 2%;
    margin-top: 13%;
    flex-basis: 70%; /* Take up half the space */
    width: 75vw;
    z-index: 1;
  }
  .SolutionFIFTHFRAMEDEVBUTTON{
    position: absolute;
    top: 88%;
    right: 0%;
    display: inline-flex;
    margin-left: 5%;
    align-items: center;
    padding: 2px 5px;
    text-decoration: none;
    color: #000000;
    font-size: 10px;
    font-weight: 900;
    cursor: pointer;
    background-color: #E8BB2C; /* Light gray background */
    border: 2px solid #ddd; /* Slightly darker gray for the border */
    border-radius: 25px;
    transition: all 0.3s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%;
  }
  /* footer 6thframe */
  .Solution-footer-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3% ;
    }
    .Solution-footer-item svg {
        fill: white;
        transition: transform 0.3s, fill 0.3s;
        height: 1rem;
        }
    
    .Solution-footer-item a {
        font-size: 12px;
        color: #ffffff;
        text-decoration: none;
        transition: color 0.3s;
        }
        .Ghazibadadresfootersolution{
            display: none;
        }
        .Solution-footer-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 5%;
            background-color: #0B8BBB;
            width: 100%;
            align-self: flex-start;
            margin-bottom: 1%;
            height: 40px;
            
            }
            .Solution-secondary-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1em 10%;
                background-color:#0B8BBB;
                width: 100%;
                margin-bottom: 0px;
                
                }
                .Solution-mission p {
                    color: #ffffff;
                    text-align: center;
                    margin-bottom: 5%;
                    font-size: 12px;
                    font-weight: 900;
                    }
                    .Solution-mission h3, .SolutionFooterSocialMediaLinks h3{
                        font-size: 14px;
                    }
                    .SolutionFooterSocialMediaLinks{
                        flex: 1;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      gap: 13px ;
                      }
                      .SolutionFooterSocialMediaLinks span{
                        display: none;
                      }
                      .SolutionFooterSocialMediaLinks svg{
                        fill: white;
                        height:1rem ;
                        
                      }
                      .Solution-copyright-section {
                        padding: 10px 0;
                        text-align: center;
                        background-color: #0B8BBB;  /* A light gray background */
                        font-size: 14px;
                        border-top: 1px solid #e0e0e0;
                        width: 100%;
                        margin-bottom: 0px;
                        margin-top: 1%;
                        }
                        .Solution-copyright-section p {
                            margin: 0;
                            color: #ffffff;  /* Slightly dark text color */
                            font-size: 12px;
                            }
                            
                            .Solution-copyright-section a {
                            color: #ffffff;  /* Classic link color */
                            text-decoration: none;
                            margin: 0 5px;
                            }
                            
                            .Solution-copyright-section a:hover {
                            text-decoration: underline;
                            color: gold;
                            }

}
@media screen and (min-width: 769px) and (max-width: 1025px) {
  .Solution-FirstCardHeader {
    font-size: 30px; /* Adjust the font size as needed */
    color: #0B8BBB; /* Adjust the font color as needed */
    margin-top: 160px ; /* Adjust the margin as needed */
    text-align: center; /* Centers the text */
    font-weight: bold;
    /* Add any other desired styling here */
    display: block; /* So it's above the text */
    margin-bottom: 20px;
    
    
    padding: 2px;
    
  }
  .Solution-FirstCardAI {
    position: fixed;
    width: 22%; 
    border-radius: 8px;
    object-fit: cover;
    height: auto;
    /* Align the image to the top of its container */
    margin-left: 80%;
    margin-top: 8%;
    z-index: 0;
    transition: transform 0.3s, box-shadow 0.3s; 
   
  }
  .SolutionFIRSTFRAMEAIBUTTON {
    position: absolute;
    top: 60%;
    right: 30%;
    display: inline-flex;
    align-items: center;
    padding: 15px 25px;
    text-decoration: none;
    color: #000000;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
    background-color: #E8BB2C; /* Light gray background */
    border: 2px solid #ddd; /* Slightly darker gray for the border */
    border-radius: 25px;
    transition: all 0.3s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
   
  }
  .Solution-FirstCardText {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
    padding: 30px;
    flex-basis: 70%; /* Take up half the space */
    width: 80%;
    z-index: 1;
    text-align: justify;
  }
  .Solution-SecondCardAI {
    position: fixed;
    width: 24%; 
    border-radius: 8px;
    object-fit: cover;
    height: auto;
    /* Align the image to the top of its container */
    margin-left: 75%;
    margin-top: 30%;  
    z-index: 0;
    transition: transform 0.3s, box-shadow 0.3s; 
   
  }
  .Solution-SecondCardText {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
    padding: 30px;
    
    
    width: 80%;
    z-index: 1;
    text-align: justify;

  }
  .SolutionSECONDFRAMEBUSINESSBUTTON{
    position: absolute;
    top: 65%;
    right: 25%;
    display: inline-flex;
    align-items: center;
    padding: 15px 25px;
    text-decoration: none;
    color: #000000;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
    background-color: #E8BB2C; /* Light gray background */
    border: 2px solid #ddd; /* Slightly darker gray for the border */
    border-radius: 25px;
    transition: all 0.3s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
   
  }
  .Solution-ThirdCardHeader {
    font-size: 34px; /* Adjust the font size as needed */
    color: #0B8BBB; /* Adjust the font color as needed */
    margin-top: 100px ; /* Adjust the margin as needed */
    text-align: center; /* Centers the text */
    font-weight: bold;
    /* Add any other desired styling here */
    display: block; /* So it's above the text */
    margin-bottom: 20px;
    
    padding: 2px;
    
    
  }
  .Solution-ThirdCardAI {
    position: fixed;
    width: 25%; 
    border-radius: 8px;
    object-fit: cover;
    height: auto;
    /* Align the image to the top of its container */
    margin-left: 75%;
    margin-top: 18%;
    z-index: 0;
    transition: transform 0.3s, box-shadow 0.3s; 
   
  }
  .Solution-ThirdCardText {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
    padding: 30px;
    
    text-align: justify;
    width: 80%;
    z-index: 1;
  }
  .SolutionTHIRDFRAMEFINANCEBUTTON{
    position: absolute;
    top: 60%;
    right: 25%;
    display: inline-flex;
    align-items: center;
    padding: 15px 25px;
    text-decoration: none;
    color: #000000;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
    background-color: #E8BB2C; /* Light gray background */
    border: 2px solid #ddd; /* Slightly darker gray for the border */
    border-radius: 25px;
    transition: all 0.3s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
   
  }
  .Solution-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: max-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .Solution-modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .Solution-FourthCardAI {
    position: fixed;
    width: 24%; 
    border-radius: 8px;
    object-fit: cover;
    height: auto;
    /* Align the image to the top of its container */
    margin-left: 75%;
    margin-top: 22%;
    z-index: 0;
    transition: transform 0.3s, box-shadow 0.3s; 
   
  }
  .Solution-FourthCardText {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
    padding: 30px;
    font-weight: 400;
    text-align: justify;
    width: 80%;
    z-index: 1;
  }
  .Solution-card {
    flex: 1; 
    max-width: calc(100% - 40px); 
    height: 98vh;
    box-sizing: border-box;
    padding: 20px;
    margin: 10px;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    position: relative;
    
    
    
  }
  .SolutionFOURTHFRAMESALESBUTTON{
    position: absolute;
    top: 65%;
    right: 35%;
    display: inline-flex;
    align-items: center;
    padding: 15px 25px;
    text-decoration: none;
    color: #000000;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
    background-color: #E8BB2C; /* Light gray background */
    border: 2px solid #ddd; /* Slightly darker gray for the border */
    border-radius: 25px;
    transition: all 0.3s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
   
  }
  .Solution-FifthCardAI {
    position: absolute;
    width: 50%; 
    top: 0%;
    border-radius: 8px;
    object-fit: cover;
    height: auto;
    /* Align the image to the top of its container */
    margin-left: 65%;
   
    
    transition: transform 0.3s, box-shadow 0.3s; 
   
  }
  .Solution-FifthCardText {
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
    padding: 30px;
    
    text-align: justify;
    width: 80%;
    z-index: 1;
  }
  .SolutionFIFTHFRAMEDEVBUTTON {
    position: absolute;
    top: 69%;
    right: 20%;
    display: inline-flex;
    align-items: center;
    padding: 15px 20px;
    text-decoration: none;
    color: #000000;
    font-size: 20px;
    font-weight: 900;
    cursor: pointer;
    background-color: #E8BB2C;
    border: 2px solid #ddd;
    border-radius: 25px;
    transition: all 0.3s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}
.Solution-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  background-color: #0B8BBB;
  width: 100%;
  align-self: flex-start;
  margin-bottom: 10px;
  height: 40px;
  
  
  }
 
}
