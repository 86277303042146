.BharatComponent-container {
    text-align: center;
    margin: 1%;
    padding: 1%;
}

.BharatComponent-heading {
    color: #333; /* Change this to your preferred color */
    font-size: 2em; /* Adjust font size as needed */
    margin-bottom: 2%;
}
.BharatComponent-card {
    background-color: #fff; /* Card background color */
    border: 1px solid #000000; /* Card border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 1%;
    margin: 1% auto;
    max-width: 300px; /* Adjust width as needed */
    box-shadow: 2px 2px 2px black;
}

.BharatComponent-card h2 {
    color: #ffffff; /* Title color */
    font-size: 1.5em;
    margin-bottom: 10px;
    text-transform: uppercase;
    background-color: #0B8BBB;
}

.BharatComponent-card ul {
    list-style: none; /* Removes bullet points */
    padding: 0;
    margin-bottom: 20px;
}

.BharatComponent-card ul li {
    color: #666; /* Feature list color */
    font-size: 0.95rem;
    margin-bottom: 5px;
    text-align: left;
    font-weight: 600;
}

.BharatComponent-card p {
    color: #ffffff; /* Price color */
    font-size: 1.2em;
    font-weight: bold;
    background-color: #03BAA8;

}
.BharatComponent-button {
    background-color: #0B8BBB;
    border: none;
    border-radius: 4px;
    color: white;
    padding: 1% 2%;
    margin: 5px;
    font-size: 1em;
    cursor: pointer;
}

.BharatComponent-price {
    color: #ffffff; /* or any color that fits your design */
    text-decoration: none; /* optional, removes the underline */
    
}

.BharatComponent-price:hover {
   color: gold;
}