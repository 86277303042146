@media   (max-width: 768px){
    .Career-card-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Two columns */
        grid-template-rows: repeat(3, auto); /* Three rows */
        gap: 1%; /* Space between cards */
        padding: 0px; /* Padding around the entire grid */
       
    }
    .Career-card1 {
        background-color: #0B8BBB; /* A light gray background for the cards */
        padding: 1%;
        border-radius: 5px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
        display: flex;
        flex-direction: column;
       font-size: 10px;
       font-weight: 900;
        align-items: top    ;
        justify-content: left;
        gap: 0px;
        height: 80px; /* Adjust the height as necessary */
        width: 96vw;
        transition: transform 0.3s ease-out; 
        white-space: nowrap; 
        margin-left: 1%;
        
    }
    .Career-card1-flex{
        display: flex;
        
        gap: 10px   ;
    }
    .Career-card1:hover{
    
        transform: scale(1.0) translateY(-5px);
    }
    .Career-card1-content-flex{
        display: flex;
        flex-direction: column;
        gap: 0px   ;
    }
    .Career-card1-content h6{
        text-transform: uppercase;
        font-size: 16px;
        color: white;
    }
    .Career-card1-Experience{
        font-family: 'Noto Sans', sans-serif;
        font-size: 10px;
        color: white;
    }
    .allbrs{
        display: none;
    }
    .Career-businessanalyst-apply-button-card1 {
        display: inline-block;
        padding: 0px 2px;
        background-color: #ffffff;
        color: #0B8BBB;
        text-align: center;
        border-radius: 5px;
        text-decoration: none; /* Remove the underline */
        font-weight: bold;
        margin-top: 1%; /* Optional: Add some margin for spacing */
        cursor: pointer;
        transition: background-color 0.3s;
        position: relative;
        transition: transform 0.3s ease-out; 
        height: 60%;
        margin-left: 0px;
    }
    /* 2nd card */
    .Career-card2 {
        background-color: #0B8BBB; /* A light gray background for the cards */
        padding: 1%;
        border-radius: 5px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
        display: flex;
        flex-direction: column;
       font-size: 10px;
       font-weight: 900;
        align-items: top    ;
        justify-content: left;
        gap: 0px;
        height: 80px; /* Adjust the height as necessary */
        width: 96vw;
        transition: transform 0.3s ease-out; 
        white-space: nowrap; 
        margin-left: 1%;
        
    }
    .Career-card2-flex{
        display: flex;
        
        gap: 10px   ;
    }
    .Career-card2:hover{
    
        transform: scale(1.0) translateY(-5px);
    }
    .Career-card2-content-flex{
        display: flex;
        flex-direction: column;
        gap: 0px   ;
    }
    .Career-card2-content h6{
        text-transform: uppercase;
        font-size: 16px !important;
        color: white;
    }
    .Career-card2-Experience{
        font-family: 'Noto Sans', sans-serif;
        font-size: 10px;
        color: white;
    }
    
    .Career-businessanalyst-apply-buttoncard2 {
        display: inline-block;
        padding: 0px 2px;
        background-color: #ffffff;
        color: #0B8BBB;
        text-align: center;
        border-radius: 5px;
        text-decoration: none; /* Remove the underline */
        font-weight: bold;
        margin-top: 1%; /* Optional: Add some margin for spacing */
        cursor: pointer;
        transition: background-color 0.3s;
        position: relative;
        transition: transform 0.3s ease-out; 
        height: 60%;
        margin-left: 0px;
    }
    /* 3rd card */
    .Career-card3 {
        background-color: #0B8BBB; /* A light gray background for the cards */
        padding: 1%;
        border-radius: 5px;
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
        display: flex;
        flex-direction: column;
       font-size: 10px;
       font-weight: 900;
        align-items: top    ;
        justify-content: left;
        gap: 0px;
        height: 80px; /* Adjust the height as necessary */
        width: 96vw;
        transition: transform 0.3s ease-out; 
        white-space: nowrap; 
        margin-left: 1%;
        
    }
    .Career-card3-flex{
        display: flex;
        
        gap: 10px   ;
    }
    .Career-card3:hover{
    
        transform: scale(1.0) translateY(-5px);
    }
    .Career-card3-content-flex{
        display: flex;
        flex-direction: column;
        gap: 0px   ;
    }
    .Career-card3-content h6{
        text-transform: uppercase;
        font-size: 16px ;
        color: white;
    }
    .Career-card3-Experience{
        font-family: 'Noto Sans', sans-serif;
        font-size: 8px;
        color: white;
    }
    
    .Career-businessanalyst-apply-buttoncard3 {
        display: inline-block;
        padding: 0px 2px;
        background-color: #ffffff;
        color: #0B8BBB;
        text-align: center;
        border-radius: 5px;
        text-decoration: none; /* Remove the underline */
        font-weight: bold;
        margin-top: 1%; /* Optional: Add some margin for spacing */
        cursor: pointer;
        transition: background-color 0.3s;
        position: relative;
        transition: transform 0.3s ease-out; 
        height: 60%;
        margin-left: 0px;
    }
    /* 4thcard */
    .Career-card4 {
        background-color: #0B8BBB; /* A light gray background for the cards */
        padding: 1%;
        border-radius: 5px;
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
        display: flex;
        flex-direction: column;
       font-size: 10px;
       font-weight: 900;
        align-items: top    ;
        justify-content: left;
        gap: 0px;
        height: 80px; /* Adjust the height as necessary */
        width: 96vw;
        transition: transform 0.3s ease-out; 
        white-space: nowrap; 
        margin-left: 1%;
        
    }
    .Career-card4-flex{
        display: flex;
        
        gap: 10px   ;
    }
    .Career-card4:hover{
    
        transform: scale(1.0) translateY(-5px);
    }
    .Career-card4-content-flex{
        display: flex;
        flex-direction: column;
        gap: 0px   ;
    }
    .Career-card4-content h6{
        text-transform: uppercase;
        font-size: 16px ;
        color: white;
    }
    .Career-card4-Experience{
        font-family: 'Noto Sans', sans-serif;
        font-size: 10px;
        color: white;
    }
    
    .Career-businessanalyst-apply-buttoncard4 {
        display: inline-block;
        padding: 0px 2px;
        background-color: #ffffff;
        color: #0B8BBB;
        text-align: center;
        border-radius: 5px;
        text-decoration: none; /* Remove the underline */
        font-weight: bold;
        margin-top: 1%; /* Optional: Add some margin for spacing */
        cursor: pointer;
        transition: background-color 0.3s;
        position: relative;
        transition: transform 0.3s ease-out; 
        height: 60%;
        margin-left: 0px;
    }
    /* 5th card */
    .Career-card5 {
        background-color: #0B8BBB; /* A light gray background for the cards */
        padding: 1%;
        border-radius: 5px;
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
        display: flex;
        flex-direction: column;
       font-size: 10px;
       font-weight: 900;
        align-items: top    ;
        justify-content: left;
        gap: 0px;
        height: 80px; /* Adjust the height as necessary */
        width: 96vw;
        transition: transform 0.3s ease-out; 
        white-space: nowrap; 
        margin-left: 1%;
        
    }
    .Career-card5-flex{
        display: flex;
        
        gap: 10px   ;
    }
    .Career-card5:hover{
    
        transform: scale(1.0) translateY(-5px);
    }
    .Career-card5-content-flex{
        display: flex;
        flex-direction: column;
        gap: 0px   ;
    }
    .Career-card5-content h6{
        text-transform: uppercase;
        font-size: 16px ;
        color: white;
    }
    .Career-card5-Experience{
        font-family: 'Noto Sans', sans-serif;
        font-size: 10px;
        color: white;
    }
    
    .Career-businessanalyst-apply-buttoncard5 {
        display: inline-block;
        padding: 0px 2px;
        background-color: #ffffff;
        color: #0B8BBB;
        text-align: center;
        border-radius: 5px;
        text-decoration: none; /* Remove the underline */
        font-weight: bold;
        margin-top: 1%; /* Optional: Add some margin for spacing */
        cursor: pointer;
        transition: background-color 0.3s;
        position: relative;
        transition: transform 0.3s ease-out; 
        height: 60%;
        margin-left: 0px;
    }
    /* 6th card */
    .Career-card6 {
        background-color: #0B8BBB; /* A light gray background for the cards */
        padding: 1%;
        border-radius: 5px;
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
        display: flex;
        flex-direction: column;
       font-size: 10px;
       font-weight: 900;
        align-items: top    ;
        justify-content: left;
        gap: 0px;
        height: 80px; /* Adjust the height as necessary */
        width: 96vw;
        transition: transform 0.3s ease-out; 
        white-space: nowrap; 
        margin-left: 1%;
        
    }
    .Career-card6-flex{
        display: flex;
        
        gap: 10px   ;
    }.Career-card6:hover{
    
        transform: scale(1.0) translateY(-5px);
    }
    .Career-card6-content-flex{
        display: flex;
        flex-direction: column;
        gap: 0px   ;
    }
    .Career-card6-content h6{
        text-transform: uppercase;
        font-size: 16px ;
        color: white;
    }
    .Career-card6-Experience{
        font-family: 'Noto Sans', sans-serif;
        font-size: 9px;
        color: white;
    }
    
    .Career-businessanalyst-apply-buttoncard6 {
        display: inline-block;
        padding: 0px 2px;
        background-color: #ffffff;
        color: #0B8BBB;
        text-align: center;
        border-radius: 5px;
        text-decoration: none; /* Remove the underline */
        font-weight: bold;
        margin-top: 1%; /* Optional: Add some margin for spacing */
        cursor: pointer;
        transition: background-color 0.3s;
        position: relative;
        transition: transform 0.3s ease-out; 
        height: 60%;
        margin-left: 0px;
    }
}
@media screen and (min-width: 769px) and (max-width: 1025px) {
    .Career-card-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Two columns */
        grid-template-rows: repeat(3, auto); /* Three rows */
        gap: 5px; /* Space between cards */
        padding: 5px; /* Padding around the entire grid */
       
    }
    .Career-card1 {
        background-color: #0B8BBB; /* A light gray background for the cards */
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
        display: flex;
        flex-direction: column;
       font-size: 20px;
       font-weight: 900;
        align-items: top    ;
        justify-content: left;
        gap: 10px;
        height: 170px; /* Adjust the height as necessary */
        transition: transform 0.3s ease-out; 
        white-space: nowrap; 
        
    }
}
