body {
    margin: 0;
    padding: 0;
    font-family: "Arial", sans-serif;
    overflow: hidden; 
  }
  
  .scroll-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; 
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .section {
    height: 100vh;
    scroll-snap-align: start;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  .pagination {
    position: fixed;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
  }
  
  .pagination-dot {
    width: 10px;
    height: 10px;
    background: #6e6e6e;
    border-radius: 50%;
    transition: background 0.3s;
    cursor: pointer;
  }
  
  .pagination-dot:hover {
    background: #555;
  }
  
  .pagination-dot.active {
    background: gold;
  }
  /* starting */
  .Career-card-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: repeat(3, auto); /* Three rows */
    gap: 10px; /* Space between cards */
    padding: 10px; /* Padding around the entire grid */
   
}

.Career-card1 {
    background-color: #0B8BBB; /* A light gray background for the cards */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: 900;
    align-items: top;
    justify-content: left;
    gap: 10px;
    height: 170px; /* Adjust the height as necessary */
    transition: transform 0.3s ease-out; 
    white-space: nowrap; 
    
}
.Career-card1:hover{
    
    transform: scale(1.05) translateY(-5px);
}
.Career-card1-flex{
    display: flex;
    gap: 10px   ;
}
.Career-card1-content h6{
    text-transform: uppercase;
    font-size: 30px;
    color: white;
}
.Career-card1 svg{
    fill: rgb(0, 0, 0);
    margin-top: 4px;
}
.Career-card1-Experience{
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    color: white;
}

  
.Career-card1-content-flex{
    display: flex;
    gap: 180px   ;
}
  
.Career-businessanalyst-apply-button-card1 {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #0B8BBB;
    text-align: center;
    border-radius: 5px;
    text-decoration: none; /* Remove the underline */
    font-weight: bold;
    margin-top: 10px; /* Optional: Add some margin for spacing */
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    transition: transform 0.3s ease-out; 
    height: 60%;
    margin-left: 70px;
}

.Career-businessanalyst-apply-button-card1:hover {
    background-color: #0B8BBB; /* A darker shade for hover */
    color: white;
    box-shadow: 2px 4px 6px #000000;
    transform: scale(1.05) translateY(-5px);
   
}
/* 2ndcard */
.Career-card2 {
    background-color: #0B8BBB; /* A light gray background for the cards */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
    display: flex;
    flex-direction: column;
   font-size: 20px;
   font-weight: 900;
    align-items: top    ;
    justify-content: left;
    gap: 10px;
    height: 170px; /* Adjust the height as necessary */
    transition: transform 0.3s ease-out; 
    white-space: nowrap; 
    
}
.Career-card2:hover{
  
    transform: scale(1.05) translateY(-5px);
}
.Career-card2-flex{
    display: flex;
    gap: 10px   ;
}
.Career-card2-content h6{
    text-transform: uppercase;
    font-size: 30px;
    color: white;
}
.Career-card2 svg{
    fill: rgb(0, 0, 0);
    margin-top: 4px;
}
.Career-card2-Experience{
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    color: white;
}

  
.Career-card2-content-flex{
    display: flex;
    gap: 210px   ;
}
  
.Career-businessanalyst-apply-buttoncard2 {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #0B8BBB;
    text-align: center;
    border-radius: 5px;
    text-decoration: none; /* Remove the underline */
    font-weight: bold;
    margin-top: 10px; /* Optional: Add some margin for spacing */
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    transition: transform 0.3s ease-out; 
    height: 60%;
}

.Career-businessanalyst-apply-buttoncard2:hover {
    background-color: #0B8BBB; /* A darker shade for hover */
    color: white;
    box-shadow: 2px 4px 6px #000000;
    transform: scale(1.05) translateY(-5px);
   
}
/* 3rd card */
.Career-card3 {
    background-color: #0B8BBB; /* A light gray background for the cards */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
    display: flex;
    flex-direction: column;
   font-size: 20px;
   font-weight: 900;
    align-items: top    ;
    justify-content: left;
    gap: 10px;
    height: 170px; /* Adjust the height as necessary */
    transition: transform 0.3s ease-out; 
    white-space: nowrap; 
    
}
.Career-card3:hover{
    
    transform: scale(1.05) translateY(-5px);
}
.Career-card3-flex{
    display: flex;
    gap: 10px   ;
}
.Career-card3-content h6{
    text-transform: uppercase;
    font-size: 30px;
    color: white;
}
.Career-card3-flex svg{
    fill: rgb(0, 0, 0);
    margin-top: 4px;
}
.Career-card3-Experience{
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    color: white;
}

  
.Career-card3-content-flex{
    display: flex;
    gap: 180px   ;
}
  
.Career-businessanalyst-apply-buttoncard3 {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #0B8BBB;
    text-align: center;
    border-radius: 5px;
    text-decoration: none; /* Remove the underline */
    font-weight: bold;
    margin-top: 10px; /* Optional: Add some margin for spacing */
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    transition: transform 0.3s ease-out; 
    height: 60%;
}

.Career-businessanalyst-apply-buttoncard3:hover {
    background-color: #0B8BBB; /* A darker shade for hover */
    color: white;
    box-shadow: 2px 4px 6px #000000;
    transform: scale(1.05) translateY(-5px);
   
}

  /* fourthcard */
  
  .Career-card4 {
    background-color: #0B8BBB; /* A light gray background for the cards */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
    display: flex;
    flex-direction: column;
   font-size: 20px;
   font-weight: 900;
    align-items: top    ;
    justify-content: left;
    gap: 10px;
    height: 170px; /* Adjust the height as necessary */
    transition: transform 0.3s ease-out; 
    white-space: nowrap; 
    
}
.Career-card4:hover{
     
    transform: scale(1.05) translateY(-5px);
}
.Career-card4-flex{
    display: flex;
    gap: 10px   ;
}
.Career-card4-content h6{
    text-transform: uppercase;
    font-size: 30px;
    color: white;
}
.Career-card4-flex svg{
    fill: rgb(0, 0, 0);
    margin-top: 4px;
}
.Career-card4-Experience{
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    color: white;
}

  
.Career-card4-content-flex{
    display: flex;
    gap: 180px   ;
}
  
.Career-businessanalyst-apply-buttoncard4 {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #0B8BBB;
    text-align: center;
    border-radius: 5px;
    text-decoration: none; /* Remove the underline */
    font-weight: bold;
    margin-top: 10px; /* Optional: Add some margin for spacing */
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    transition: transform 0.3s ease-out; 
    height: 60%;
}

.Career-businessanalyst-apply-buttoncard4:hover {
    background-color: #0B8BBB; /* A darker shade for hover */
    color: white;
    box-shadow: 2px 4px 6px #000000;
    transform: scale(1.05) translateY(-5px);
   
}
/* 5thcard */
.Career-card5 {
    background-color: #0B8BBB; /* A light gray background for the cards */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
    display: flex;
    flex-direction: column;
   font-size: 20px;
   font-weight: 900;
    align-items: top    ;
    justify-content: left;
    gap: 10px;
    height: 170px; /* Adjust the height as necessary */
    transition: transform 0.3s ease-out; 
    white-space: nowrap; 
    
}
.Career-card5:hover{
    
    transform: scale(1.05) translateY(-5px);
}
.Career-card5-flex{
    display: flex;
    gap: 10px   ;
}
.Career-card5-content h6{
    text-transform: uppercase;
    font-size: 30px;
    color: white;
}
.Career-card3-flex svg{
    fill: rgb(0, 0, 0);
    margin-top: 4px;
}
.Career-card5-Experience{
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    color: white;
}

  
.Career-card5-content-flex{
    display: flex;
    gap: 180px   ;
}
  
.Career-businessanalyst-apply-buttoncard5 {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #0B8BBB;
    text-align: center;
    border-radius: 5px;
    text-decoration: none; /* Remove the underline */
    font-weight: bold;
    margin-top: 10px; /* Optional: Add some margin for spacing */
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    transition: transform 0.3s ease-out; 
    height: 60%;
    margin-left: 70px;
}

.Career-businessanalyst-apply-buttoncard5:hover {
    background-color: #0B8BBB; /* A darker shade for hover */
    color: white;
    box-shadow: 2px 4px 6px #000000;
    transform: scale(1.05) translateY(-5px);
   
}
/* 6th */
.Career-card6 {
    background-color: #0B8BBB; /* A light gray background for the cards */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* A subtle shadow for the cards */
    display: flex;
    flex-direction: column;
   font-size: 20px;
   font-weight: 900;
    align-items: top    ;
    justify-content: left;
    gap: 10px;
    height: 170px; /* Adjust the height as necessary */
    transition: transform 0.3s ease-out; 
    white-space: nowrap; 
    
}
.Career-card6:hover{
   
    transform: scale(1.05) translateY(-5px);
}
.Career-card6-flex{
    display: flex;
    gap: 10px   ;
}
.Career-card6-content h6{
    text-transform: uppercase;
    font-size: 30px;
    color: white;
}
.Career-card6-flex svg{
    fill: rgb(0, 0, 0);
    margin-top: 4px;
}
.Career-card6-Experience{
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    color: white;
}

  
.Career-card6-content-flex{
    display: flex;
    gap: 110px   ;
}
  
.Career-businessanalyst-apply-buttoncard6 {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #0B8BBB;
    text-align: center;
    border-radius: 5px;
    text-decoration: none; /* Remove the underline */
    font-weight: bold;
    margin-top: 10px; /* Optional: Add some margin for spacing */
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    transition: transform 0.3s ease-out; 
    height: 60%;
    
}

.Career-businessanalyst-apply-buttoncard6:hover {
    background-color: #0B8BBB; /* A darker shade for hover */
    color: white;
    box-shadow: 2px 4px 6px #000000;
    transform: scale(1.05) translateY(-5px);
   
}