.container-Insights {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three columns of equal width */
  
  width: 95vw;
  height: 80vh; /* Adjust based on your preference */
  overflow-y: auto;
  margin: 0 auto;
  line-height: 1.5;
  color: #333;
  padding-right: 20px; /* To ensure content doesn't get obscured by faux scrollbar */
  box-shadow: 5px 5px 15px #0B8BBB; /* Adjust as needed */
  /* Hide the default scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  overflow-y: scroll;
} 

.wrapper-Insights {
  display: flex;
  align-items: start;
  position: relative;
}
/* .faux-scrollbar-Insights {
  position: absolute;
  right: 1%;
  top: 0;
  width: 10px;
  height: 40vh; 
  background: transparent;
  border-radius: 5px;
}

.faux-thumb-Insights {
  width: 10px;
  height: 10px; 
  background: #0B8BBB; 
  border-radius: 5px;
  position: relative; 
  transition: transform 0.1s; 
} */
/* starting */
.InsightsTest-card {
  width: 90%;
  height: 50vh;
  background-image: url('images/AIjob.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
  
}
.InsightsTest-card:hover {
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-card-hover-content h3{
  color: goldenrod;
  margin-left: 2%;
  margin-top: 10%;
  text-align: justify;
  font-size: 17px;
  margin-bottom: 1%;
  margin-right: 2%;
}
.InsightsTest-card-hover-content p{
  color: white;
  margin-left: 2%;
  text-align: justify;
  padding: 1%;
  margin-right: 2%;
  font-size: 0.75rem;
}
.insightstestLink{
  text-decoration: none;
}
.InsightsTest-card-hover-content {
  position: absolute;
  right: -100%; /* Start off the screen to the right */
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: right 0.5s ease; /* Smooth transition for sliding in */
  z-index: 3; /* Above the overlay and background */
}
.InsightsTest-card:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}

/* Styles for the heading and introduction text */
.InsightsTest-card-hover-heading,
.InsightsTest-card-hover-intro {
  color: #000000; /* Text color */
  padding: 0 20px; /* Padding for text */
  /* Add any additional styling you need for the text */
}
.InsightsTest-card-header {
  text-align: left;
  color: #E8BB2D; /* Black color */
  font-size: 2.4em; /* Adjust font size as needed */
  font-weight: 900;
  text-transform: uppercase; /* Uppercase for 'BLOG' */
  margin-top: 2%;
  margin-left: 2%;
  
 
}


.InsightsTest-card-header,
.InsightsTest-card-title {
  position: relative; /* To ensure it's on top of the overlay */
  z-index: 2; /* Above the overlay */
}

.InsightsTest-card-link {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits text color from parent */
  display: block; /* Makes the link block level, covering the entire card area */
}
.InsightsTest-read-more-button {
  background-color: #0B8BBB; /* Button background color */
  color: #FFF; /* Button text color */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
  margin-top: 1%;
  border-radius: 5%;
 
}
.InsightsTest-read-more-button:hover{
  background-color: white;
  color: #0B8BBB;
  font-weight: 600 ;
}
.InsightsTest-arrow {
  margin-left: 10px;
  display: inline-block;
  transition: margin-left 0.3s ease;
}

.InsightsTest-read-more-button:hover .InsightsTest-arrow {
  margin-left: 20px; /* Increase margin to move the arrow to the right on hover */
  
}
/* 2nd card */
.InsightsTest-Secondcard {
  width: 90%;
  height: 50vh;
  background-image: url('images/ConsumerBehaviourFifth.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
 
 
}
.InsightsTest-Secondcard:hover{
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-Secondcard:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}
.InsightsTest-Secondcard-header {
  text-align: left;
  color: #E8BB2D; /* Black color */
  font-size: 2.4em; /* Adjust font size as needed */
  font-weight: 900;
  text-transform: uppercase; /* Uppercase for 'BLOG' */
  margin-top: 2%;
  margin-left: 2%;
  
 
}
.InsightsTest-secondcard-hover-content h3{
  color: #E8BB2D;
  margin-left: 3%;
}
/* 3rd   card */
.InsightsTest-Thirdcard {
  width: 90%;
  height: 50vh;
  background-image: url('images/InfluencerMarketingSixth.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
 
}
.InsightsTest-Thirdcard:hover{
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-Thirdcard:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}
.InsightsTest-Thirdcard-header {
  text-align: left;
  color: #E8BB2D; /* Black color */
  font-size: 2.4em; /* Adjust font size as needed */
  font-weight: 900;
  text-transform: uppercase; /* Uppercase for 'BLOG' */
  margin-top: 2%;
  margin-left: 2%;

 
}
.InsightsTest-Thirdcard-hover-content h3{
  color: #E8BB2D;
  margin-left: 3%;
  text-align: left;
}
.InsightsTest-Thirdcard-hover-content p{
  color: #ffffff;
  margin-left: 2%;
}

/* forth */
.InsightsTest-Fourthcard {
  width: 90%;
  height: 50vh;
  background-image: url('images/EmployeeRetenCard.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
 
}
.InsightsTest-Fourthcard:hover{
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-Fourthcard:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}

.InsightsTest-Thirdcard-hover-content h3{
  color: #E8BB2D;
  margin-left: 3%;
  text-align: left;
}
.InsightsTest-Thirdcard-hover-content p{
  color: #ffffff;
  margin-left: 2%;
}
/* fifthcard */
.InsightsTest-Fifthcard {
  width: 90%;
  height: 50vh;
  background-image: url('images/AINOMONEY.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
 
}
.InsightsTest-Fifthcard:hover{
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-Fifthcard:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}

.InsightsTest-Thirdcard-hover-content h3{
  color: #E8BB2D;
  margin-left: 3%;
  text-align: left;
}
.InsightsTest-Thirdcard-hover-content p{
  color: #ffffff;
  margin-left: 2%;
}
/* sixth */
.InsightsTest-Sixthcard {
  width: 90%;
  height: 50vh;
  background-image: url('images/OrganizationSuccessEight.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
 
}
.InsightsTest-Sixthcard:hover{
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-Sixthcard:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}

.InsightsTest-Thirdcard-hover-content h3{
  color: #E8BB2D;
  margin-left: 3%;
  text-align: left;
}
.InsightsTest-Thirdcard-hover-content p{
  color: #ffffff;
  margin-left: 2%;
}
/* seventh card */
.InsightsTest-Seventhcard {
  width: 90%;
  height: 50vh;
  background-image: url('images/MarketingplusAI.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
 
}
.InsightsTest-Seventhcard:hover{
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-Seventhcard:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}

.InsightsTest-Thirdcard-hover-content h3{
  color: #E8BB2D;
  margin-left: 3%;
  text-align: left;
}
.InsightsTest-Thirdcard-hover-content p{
  color: #ffffff;
  margin-left: 2%;
}
/* 8th card */
.InsightsTest-Eightcard {
  width: 90%;
  height: 50vh;
  background-image: url('images/DigitalmarketingCard.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
 
}
.InsightsTest-Eightcard:hover{
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-Eightcard:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}
/* 9th */
.InsightsTest-Ninecard {
  width: 90%;
  height: 50vh;
  background-image: url('images/AIVSHUMANCARD.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
 
}
.InsightsTest-Ninecard:hover{
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-Ninecard:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}
/* 10th */
.InsightsTest-Tencard {
  width: 125%;
  height: 50vh;
  background-image: url('images/BusinessPsyThird.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
  margin-left: 90%;
 
}
.InsightsTest-Tencard:hover{
  background: linear-gradient(to right, #473F44, #667D95);
 
}
.InsightsTest-Tencard:hover .InsightsTest-card-hover-content {
  right: 0; /* Slide in to be fully visible */
}
@media   (max-width: 768px) {
  .container-Insights {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates three columns of equal width */
    
    width: 95vw;
    height: 80vh; /* Adjust based on your preference */
    overflow-y: auto;
    margin: 0 auto;
    line-height: 1.5;
    color: #333;
    padding-right: 20px; /* To ensure content doesn't get obscured by faux scrollbar */
    box-shadow: 5px 5px 15px #0B8BBB; /* Adjust as needed */
    /* Hide the default scrollbar */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    overflow-y: scroll;
  } 
  .InsightsTest-card {
    width: 95%;
    height: 50vh;
    background-image: url('images/AIjob.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2px;
    margin: 20px; /* Adjust as needed */
    overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
    position: relative; /* Needed for positioning the overlay */
    cursor: pointer;
    transition: background 0.3s ease; 
    
  }
  .InsightsTest-Secondcard {
    width: 95%;
    height: 50vh;
    background-image: url('images/ConsumerBehaviourFifth.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2px;
    margin: 20px; /* Adjust as needed */
    overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
    position: relative; /* Needed for positioning the overlay */
    cursor: pointer;
    transition: background 0.3s ease; 
   
   
  }
  .InsightsTest-Thirdcard {
    width: 95%;
    height: 50vh;
    background-image: url('images/InfluencerMarketingSixth.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2px;
    margin: 20px; /* Adjust as needed */
    overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
    position: relative; /* Needed for positioning the overlay */
    cursor: pointer;
    transition: background 0.3s ease; 
   
  }
  .InsightsTest-Fourthcard {
    width: 95%;
    height: 50vh;
    background-image: url('images/EmployeeRetenCard.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2px;
    margin: 20px; /* Adjust as needed */
    overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
    position: relative; /* Needed for positioning the overlay */
    cursor: pointer;
    transition: background 0.3s ease; 
   
  }
  .InsightsTest-Fifthcard {
    width: 95%;
    height: 50vh;
    background-image: url('images/AINOMONEY.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2px;
    margin: 20px; /* Adjust as needed */
    overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
    position: relative; /* Needed for positioning the overlay */
    cursor: pointer;
    transition: background 0.3s ease; 
   
  }
  .InsightsTest-Sixthcard {
    width: 95%;
    height: 50vh;
    background-image: url('images/OrganizationSuccessEight.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2px;
    margin: 20px; /* Adjust as needed */
    overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
    position: relative; /* Needed for positioning the overlay */
    cursor: pointer;
    transition: background 0.3s ease; 
   
  }
  .InsightsTest-Seventhcard {
    width: 95%;
    height: 50vh;
    background-image: url('images/MarketingplusAI.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2px;
    margin: 20px; /* Adjust as needed */
    overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
    position: relative; /* Needed for positioning the overlay */
    cursor: pointer;
    transition: background 0.3s ease; 
   
  }
  .InsightsTest-Eightcard {
    width: 95%;
    height: 50vh;
    background-image: url('images/DigitalmarketingCard.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2px;
    margin: 20px; /* Adjust as needed */
    overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
    position: relative; /* Needed for positioning the overlay */
    cursor: pointer;
    transition: background 0.3s ease; 
   
  }
 
.InsightsTest-Ninecard {
  width: 95%;
  height: 50vh;
  background-image: url('images/AIVSHUMANCARD.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
 
}
.InsightsTest-Tencard {
  width: 95%;
  height: 50vh;
  background-image: url('images/BusinessPsyThird.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  margin: 20px; /* Adjust as needed */
  overflow: hidden; /* Optional, in case you want to ensure the content stays within the card */
  position: relative; /* Needed for positioning the overlay */
  cursor: pointer;
  transition: background 0.3s ease; 
  
 
}
}


