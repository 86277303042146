.shipping-delivery {
    background-color: #f2f2f2;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 20px;
    margin: 20px;
    text-align: center;
}

.shipping-delivery h2 {
    color: #333;
    font-size: 1.5em;
}

.shipping-delivery p {
    color: #666;
    font-size: 1em;
}
