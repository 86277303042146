
@media   (max-width: 768px) {
    .changing-text {
        color: rgb(230, 182, 42);
        font-size: 35px;
        width: 200px; /* adjust this to be more than the width of the longest word */
        overflow: hidden; 
        text-align: left; 
        height: 60px;
        
        
    
        
      }
      .fixed-text{
        margin-right: 15px;
        color: #ffffff;
        margin-left: 85px;
        font-size:30px ;
        margin-bottom: 18px;
      }
      .subtext {
        color: #ffffff;
        font-size: 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        
        
      }
      .subtext span {
        color: rgb(230, 182, 42);
        font-size: 35px;
      }
      /* 2ndframe */
      .images-container {
        display: flex;
        flex-direction: column;
        gap: 10%;
        
      }
      .image-section img {
        width: 45vw; 
        height: auto; 
        border-radius: 10px; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      .reasons-container h2 {
        font-size: 20px;
        color: #E8BB2C;
        margin-bottom: 4%;
        font-weight: bold;
        border-bottom: 2px solid #333; 
        padding-bottom: 10px;
        margin-top: 65%;
      }
      .icon-container svg{
        height: 1rem;
      }
      .icon-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 0px;
      }
      .Clients {
        font-size: 15px; 
        color: #0B8BBB; 
        font-weight: bold;
        margin-top: 10px;
        
        
      }
      .OurPlans-heading{
        display: none;
      }
      .detailed-text {
        font-size: 15px; /* Adjust based on your preference */
        color: #03BAA8; /* Adjust color as needed */
        margin-top: 1%; /* Provides a bit of spacing */
      }
      .reasons-container {
       display: grid;
        align-items: center;
        padding: 0px;
        text-align: center;
        background-color: #ffffff; 
       height: 65vh;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
        margin: 0px; 
        max-width: 100%; 
        margin-left: auto;
        margin-right: auto;
      }
      .img2ndframewhywork{
        margin-left: 25%;
        
      }
      .laptop-image {
        width: 100%;       /* Ensure the image takes up the full width of the .laptop-screen */
        height: 100%;      /* Ensure the image takes up the full height of the .laptop-screen */
        object-fit: cover; /* This will make sure the image scales nicely inside its container */
        border-radius: 12px; /* To match the rounded corners of .laptop-screen*/
        display: block;    /* To remove any potential spacing issues */
      }
      .laptop-screen {
        width: 68vw; /* subtracting half of the gap to maintain the gap properly between devices */
        height: 30vh; /* 16:9 aspect ratio, which is common for laptop screens */
        border: 3px solid #000; /* border representing laptop screen edges */
        border-radius: 15px; 
        background: #fff; /* representing the laptop screen; you can also use an image or gradient */
        position: relative;
        margin-top: 0;
       
        
      }
      .device-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
        gap: 30px; /* spacing between the two laptop screens */
     
      }
      .Home-website-name {
    
    
        color: #ffffff;
        background-color: #0B8BBB;
        padding: 2px 5px;
        border-radius: 20px;
        width: 68vw;  
        font-size: 10px;
        text-align: center;
        box-sizing: border-box;
        margin-top: 3%; 
    }
   
  .Home-case-study-name {
    
         
              
    color: #ffffff;
    background-color: #0B8BBB;
    padding: 2px 5px;
    border-radius: 20px;
    width: 68vw;  
    font-size: 10px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 3%;
  }
  .other-device-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  
  .other-laptop-screen {
    width: 68vw; /* subtracting half of the gap to maintain the gap properly between devices */
        height: 30vh; /* 16:9 aspect ratio, which is common for laptop screens */
        border: 3px solid #000; /* border representing laptop screen edges */
        border-radius: 15px; 
        background: #fff; /* representing the laptop screen; you can also use an image or gradient */
        position: relative;
        margin-top: 0;
  }
  .Home-other-website-name {
    
    color: #ffffff;
    background-color: #0B8BBB;
    padding: 2px 5px;
    border-radius: 20px;
    width: 68vw;  
    font-size: 10px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 3%; 
  }
  .Home-other-case-study-name {
    
    color: #ffffff;
    background-color: #0B8BBB;
    padding: 2px 5px;
    border-radius: 20px;
    width: 68vw;  
    font-size: 10px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 3%;
  }
  /* footer */
  .Solution-footer-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3% ;
    }
    .Solution-footer-item svg {
        fill: white;
        transition: transform 0.3s, fill 0.3s;
        height: 1rem;
        }
    
    .Solution-footer-item a {
        font-size: 12px;
        color: #ffffff;
        text-decoration: none;
        transition: color 0.3s;
        }
        .Ghazibadadresfootersolution{
            display: none;
        }
        .Solution-footer-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 5%;
            background-color: #0B8BBB;
            width: 100%;
            align-self: flex-start;
            margin-bottom: 1%;
            height: 40px;
            
            }
            .Solution-secondary-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1em 10%;
                background-color:#0B8BBB;
                width: 100%;
                margin-bottom: 0px;
                
                }
                .Solution-mission p {
                    color: #ffffff;
                    text-align: center;
                    margin-bottom: 5%;
                    font-size: 12px;
                    font-weight: 900;
                    }
                    .Solution-mission h3, .SolutionFooterSocialMediaLinks h3{
                        font-size: 14px;
                    }
                    .SolutionFooterSocialMediaLinks{
                        flex: 1;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      gap: 13px ;
                      }
                      .SolutionFooterSocialMediaLinks span{
                        display: none;
                      }
                      .SolutionFooterSocialMediaLinks svg{
                        fill: white;
                        height:1rem ;
                        
                      }
                      .Solution-copyright-section {
                        padding: 10px 0;
                        text-align: center;
                        background-color: #0B8BBB;  /* A light gray background */
                        font-size: 14px;
                        border-top: 1px solid #e0e0e0;
                        width: 100%;
                        margin-bottom: 0px;
                        margin-top: 1%;
                        }
                        .Solution-copyright-section p {
                            margin: 0;
                            color: #ffffff;  /* Slightly dark text color */
                            font-size: 12px;
                            }
                            
                            .Solution-copyright-section a {
                            color: #ffffff;  /* Classic link color */
                            text-decoration: none;
                            margin: 0 5px;
                            }
                            
                            .Solution-copyright-section a:hover {
                            text-decoration: underline;
                            color: gold;
                            }
                           
                            
                          }

                          

@media screen and (min-width: 769px) and (max-width: 1025px) {
  .OurPlans-heading{
    display: none;
  }
  .image-section img {
    width: 60%; 
    height: auto; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .subtext {
    color: rgb(255, 255, 255);
    font-size: 35px;
    margin-top: 10px;
    grid-row: 2;
    margin-bottom: 100px;
    
  }
  .images-container {
    display: flex;
    flex-direction: column;
    gap: 4%;
  }
  .IMGSEO1{
    margin-right: 20%;
  }
  .reasons-container h2 {
    font-size: 28px;
    color: #E8BB2C;
    margin-bottom: 0px;
    font-weight: bold;
    border-bottom: 2px solid #333; 
    padding-bottom: 10px;
  }
  .icon-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    
  }
  .Clients {
    font-size: 25px; 
    color: #0B8BBB; 
    font-weight: bold;
    margin-top: 10px;
    
    
  }
  .detailed-text {
    font-size: 25px; /* Adjust based on your preference */
    color: #03BAA8; /* Adjust color as needed */
    margin-top: 30px; /* Provides a bit of spacing */
  }
  /* 2ndframe */
  
  .laptop-screen {
    width: 48%; /* subtracting half of the gap to maintain the gap properly between devices */
    height: auto; /* 16:9 aspect ratio, which is common for laptop screens */
    border: 3px solid #000; /* border representing laptop screen edges */
    border-radius: 15px; 
    background: #fff; /* representing the laptop screen; you can also use an image or gradient */
    position: relative;
    margin-top: 25%;
   
    
  }
  .device-container {
    display: flex;
  
    justify-content: center;
    align-items: center;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    gap: 20px; /* spacing between the two laptop screens */
    
  }
  .Home-website-name {
    
    
    color: #ffffff;
    background-color: #0B8BBB;
    padding: 5px 10px;
    border-radius: 20px;
    width: 100%;  
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 0px; 
}
.Home-case-study-name {
    
         
              
  color: #ffffff;
  background-color: #0B8BBB;
  padding: 5px 10px;
  border-radius: 20px;
  width: 100%;  
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 15px;
}
.other-device-container{
  display: flex;

  justify-content: center;
  align-items: center;
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  gap: 20px; /* spacing between the two laptop screens */
  
}
.other-laptop-screen {
  width: 48%; /* subtracting half of the gap to maintain the gap properly between devices */
  height: auto; /* 16:9 aspect ratio, which is common for laptop screens */
  border: 3px solid #000; /* border representing laptop screen edges */
  border-radius: 15px; 
  background: #fff; /* representing the laptop screen; you can also use an image or gradient */
  position: relative;
  margin-top: 20%;
 
  
}
.Home-other-website-name {
    
    
  color: #ffffff;
  background-color: #0B8BBB;
  padding: 5px 10px;
  border-radius: 20px;
  width: 100%;  
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 0px; 
}
.Home-other-case-study-name {
    
         
              
  color: #ffffff;
  background-color: #0B8BBB;
  padding: 5px 10px;
  border-radius: 20px;
  width: 100%;  
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 0px;
}
}