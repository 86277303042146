body {
    margin: 0;
    padding: 0;
    font-family: "Arial", sans-serif;
    overflow: hidden; 
  }
  
  .scroll-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; 
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .section {
    height: 100vh;
    scroll-snap-align: start;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  .pagination {
    position: fixed;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
  }
  
  .pagination-dot {
    width: 10px;
    height: 10px;
    background: #6e6e6e;
    border-radius: 50%;
    transition: background 0.3s;
    cursor: pointer;
  }
  
  .pagination-dot:hover {
    background: #555;
  }
  
  .pagination-dot.active {
    background: gold;
  }
  
  /* starting */
/* ... other styles ... */

/* AboutUs.css */
/* AboutUs.css */
.mission-container {
    display: flex;
    justify-content:center ;
    align-items:center ;
    box-shadow: 2px 2px 6px #0B8BBB;
    width: 100%;
    padding: 1px;
    gap: 15px;
    

}

.mission-header {
    font-size: 50px;
    color: #0B8BBB;
    margin-bottom: 5px;
    text-align: center;
    font-family: 'Asul', serif;
    font-weight: 900;

}
.mission-header-2nd{
    color: transparent;
    -webkit-text-stroke: 1px #0B8BBB; /* Adjust the px value for stroke thickness */
    text-stroke: 2px black; /* For browsers that might support it without prefix in the future */
    font-weight: 990; /* Optional, but can make the outline look better */
    font-size: 50px;
    font-family: 'Asul', serif;
}
.mission-content {
    max-width: 700px;
    
    font-size: 25.5px;
    color: #000000;
    font-weight: 990;
    text-align: justify;
}

/* Add more styles or tweak existing ones to match the exact design */
.Aboutus-all-three{
    display: flex;
    justify-content: center;
    align-items: center;
}
.AboutUS-Detailing-1stframe {
    /* Styles specific to this new div, such as padding or text alignment */
    text-align: left; 
    padding: 20px;
    box-shadow: 2px 2px 6px #0B8BBB;
    width: 400px;
    margin-left: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
}

.AboutUS-Detailing-1stframe img {
    /* Styles for the SVG image, e.g., width or margin adjustments */
    width: 70px;  /* adjust as needed */
    margin-bottom: 5px; /* spacing between image and header */
    
    margin-top: 5px;
   
}

.AboutUS-Detailing-1stframe h2 {
    /* Styles for the h2 header */
    font-size: 30px; 
    margin-bottom: 2px;  /* spacing between header and description */
    
    color: #0B8BBB;
}

.AboutUS-Detailing-1stframe p {
    /* Styles for the description */
    font-size: 25px; 
    font-weight: bold;
    
    color: #EDC12D;
    font-weight: 900;
}

.AboutUS-Detailing-2ndframe {
    /* Styles specific to this new div, such as padding or text alignment */
    text-align: left; 
    padding: 10px;
    box-shadow: 2px 2px 6px #0B8BBB;
    width: 400px;
    margin-left: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 35px;
}

.AboutUS-Detailing-2ndframe img {
    /* Styles for the SVG image, e.g., width or margin adjustments */
    width: 70px;  /* adjust as needed */
    margin-bottom: 5px; /* spacing between image and header */
    
    margin-top: 5px;
   
}

.AboutUS-Detailing-2ndframe h2 {
    /* Styles for the h2 header */
    font-size: 30px; 
    margin-bottom: 1px;  /* spacing between header and description */
    
    color: #0B8BBB;
}

.AboutUS-Detailing-2ndframe p {
    /* Styles for the description */
    font-size: 25px; 
    font-weight: bold;
    
    color: #EDC12D;
    font-weight: 900;
}

.AboutUS-Detailing-3rdframe img {
    /* Styles for the SVG image, e.g., width or margin adjustments */
    width: 110px;  /* adjust as needed */
    margin-bottom: 10px; /* spacing between image and header */
    
    margin-top: 0px;
   
}

.AboutUS-Detailing-3rdframe {
    /* Styles specific to this new div, such as padding or text alignment */
    text-align: left; 
    padding: 10px;
    box-shadow: 2px 2px 6px #0B8BBB;
    width: 400px;
    margin-left: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 20px;
}



.AboutUS-Detailing-3rdframe h2 {
    /* Styles for the h2 header */
    font-size: 30px; 
    margin-bottom: 10px;  /* spacing between header and description */
    
    color: #0B8BBB;
}

.AboutUS-Detailing-3rdframe p {
    /* Styles for the description */
    font-size: 25px; 
    font-weight: bold;
    
    color: #EDC12D;
    font-weight: 900;
}
/* OUR MISSION ANIMATION */
@keyframes moveDownAndFade {
    0%, 100% {
        opacity: 1;
        transform: translateY(0);
    }
    50% {
        opacity: 0;
        transform: translateY(calc(50% - 1em)); 
    }
}

@keyframes moveUpAndFade {
    0%, 100% {
        opacity: 1;
        transform: translateY(0);
    }
    50% {
        opacity: 0;
        transform: translateY(calc(-50% + 1em)); 
    }
}

@keyframes enlarge {
    0%, 25%, 100% {
        font-size: 50px;
        opacity: 1;
    }
    50% {
        font-size: 70px;
        opacity: 1;
    }
}

.movingDown {
    animation: moveDownAndFade 8s infinite;
}

.movingUp {
    animation: moveUpAndFade 8s infinite;
}

.enlarging {
    animation: enlarge 8s infinite;
}
