.firstBlog-wrapper {
  display: flex;
  align-items: start;
  position: relative;
}

.firstBlog-container {
  width: 80%;
  height: 80vh; /* Adjust based on your preference */
  overflow-y: auto;
  margin: 0 auto;
  line-height: 1.5;
  color: #333;
  padding-right: 20px; /* To ensure content doesn't get obscured by faux scrollbar */
  box-shadow: 5px 5px 15px #0B8BBB; /* Adjust as needed */
  /* Hide the default scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.firstBlog-container h1{
  text-align: center;
  color:#0B8BBB ;
  font-size: 38px;
}
.firstBlog-container h2{
  text-align: center;
  color:#0B8BBB ;
}
.firstBlog-container p,
.firstBlog-container li{
  font-size: 20px;
  padding: 10px;
}
.First-Blog-Date-Time{
  text-align: center;
  margin-bottom: 1.5%;
  
}
.firstBlog-container::-webkit-scrollbar {
  width: 0; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.firstBlog-container h1,
.firstBlog-container h2,
.firstBlog-container p,
.firstBlog-container ul
 {
  margin: 20px 0;
  
}

.faux-scrollbar {
  position: absolute;
  right: 5%;
  top: 0;
  width: 10px;
  height: 40vh; /* Should match the height of .firstBlog-container */
  background: transparent;
  border-radius: 5px;
}

.faux-thumb {
  width: 10px;
  height: 10px; /* Your desired height for the faux scrollbar */
  background: #0B8BBB; /* Your desired color for the faux scrollbar */
  border-radius: 5px;
  position: relative; /* To apply translateY */
  transition: transform 0.1s; /* For smoother movement */
}
/* Previously provided CSS... */

/* Styling for the image */
.firstBlog-container img {
  width: auto;
  height: 400px; /* Ensures the image doesn't overflow the container */
  transition: transform 0.3s ease; /* Transition effect for smooth scaling */
  display: block; /* To remove any default margin/padding around the image */
  margin: 0 auto;
}

/* Hover effect for the image */
.firstBlog-container img:hover {
  transform: scale(1.08); /* Enlarges the image by 10% */
  cursor: pointer; /* Indicates interactivity */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); /* Adjust as needed */
  
}
.firstblogContext{
  border-bottom: 2px solid black;
  padding: 10px;
  
}
@media   (max-width: 768px){
  .firstBlog-container {
    width: 85%;
    height: 80vh; /* Adjust based on your preference */
    overflow-y: auto;
    margin: 0 auto;
    line-height: 1.5;
    color: #333;
    padding-right: 20px; /* To ensure content doesn't get obscured by faux scrollbar */
    box-shadow: 5px 5px 15px #0B8BBB; /* Adjust as needed */
    /* Hide the default scrollbar */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  .firstBlog-container h1{
    text-align: center;
    color:#0B8BBB ;
    font-size: 24px;
  }
  .First-Blog-Date-Time{
    text-align: center;
    margin-bottom: 2%;
    font-size: 10px;
    
  }
  .firstBlog-container img {
    width: 250px;
    height: auto; /* Ensures the image doesn't overflow the container */
    transition: transform 0.3s ease; /* Transition effect for smooth scaling */
    display: block; /* To remove any default margin/padding around the image */
    margin: 0 auto;
  }
  .firstBlog-container h1,
.firstBlog-container h2,
.firstBlog-container p,
.firstBlog-container ul
 {
  margin: 10px 0;
  
}
.firstBlog-container p,
.firstBlog-container li{
  font-size: 16px;
  padding: 5px;
}
}