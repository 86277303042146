@media screen and (max-width: 768px) {
    .info-link {
        text-decoration: none;
        color: inherit;
        display: flex;            
        align-items: center;     
        gap:2px;
        transition: color 0.3s ease; /* Added for smooth color transition */
        font-size: 10px;
        
    }
    .social-media-links svg {
        fill: rgb(255, 255, 255);
        width: 10px;
        height: 10px;
        transition: opacity 0.3s ease, fill 0.3s ease;  /* Added fill transition */
    }
    .social-media-links {
        display: flex;
        gap: 5px;
    }
    .info-link svg{
        height: 0.6rem;
    }
}