@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#headlineText,
#subheadlineText,
#descriptionText {
    opacity: 0;  
    transition: opacity 0.3s, transform 0.3s;
}

#headlineText.animate-text,
#subheadlineText.animate-text,
#descriptionText.animate-text {
    animation: fadeIn 0.5s forwards;
}
.AI-draw-fill-effect{
    fill: transparent;
    stroke: black; 
    stroke-width: 2; 
    stroke-dasharray: 2000;  
    stroke-dashoffset: 2000;  
    animation: StrategydrawFillEffect 10s infinite; 
}

.Strategy-draw-fill-effect {
    fill: transparent;
    stroke: black; 
    stroke-width: 2; 
    stroke-dasharray: 2000;  
    stroke-dashoffset: 2000;  
    animation: StrategydrawFillEffect 10s infinite; 
}

@keyframes StrategydrawFillEffect {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    75%, 100% {
        fill: #007BFF;
    }
}
.Financedraw-fill-effect {
    fill: transparent;
    stroke: black; 
    stroke-width: 2; 
    stroke-dasharray: 2000;  
    stroke-dashoffset: 2000;  
    animation: FinancedrawFillEffect 10s infinite; 
}

@keyframes FinancedrawFillEffect {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    75%, 100% {
        fill: #007BFF;
    }
}
.Sales-draw-fill-effect {
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectSales 10s infinite;
 }
 
 @keyframes drawFillEffectSales {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
 .Brand-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectBrand 10s infinite;
 }

 @keyframes drawFillEffectBrand {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }


 .Tech-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectTech 10s infinite;
 }

 @keyframes drawFillEffectTech {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
 .Digital-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectDigital 10s infinite;
 }

 @keyframes drawFillEffectDigital {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
.DM-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectDM 10s infinite;
}
@keyframes drawFillEffectDM {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
 .Code-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectCode 10s infinite;
}
@keyframes drawFillEffectCode {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
 .Influencer-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectInf 10s infinite;
}
@keyframes drawFillEffectInf {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
 .Employee-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectEmp 10s infinite;
}
@keyframes drawFillEffectEmp {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
 .SMG-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectSMG 10s infinite;
}
@keyframes drawFillEffectSMG {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
 .Ads-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectAds 10s infinite;
}
@keyframes drawFillEffectAds {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
 .Link-draw-fill-effect{
    fill: transparent;
    stroke: black;
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawFillEffectLink 10s infinite;
}
@keyframes drawFillEffectLink {
    0% {
        fill: transparent;
        stroke-dashoffset: 2000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    
    75%, 100% {
        fill: #007BFF;
    }
 }
 