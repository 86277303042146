/* Add this import at the top of your PrivacyPolicy.css file */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');




/* PrivacyPolicy.css */

.PrivacyPolicy-container {
    padding: 10px;
   width: 100vw;
    display: flex;
    flex-direction: column;
   

  }
  
  .PrivacyPolicy-heading {
    font-size: 2em;
    text-align: center;
    margin-bottom: 5px;
    color: #0B8BBB;
  }
  
  .PrivacyPolicy-effectiveDate {
  font-size: 1em;
 
  color: #ffffff; /* Choose a color that fits your site's design */
  margin-bottom: 10px;
  font-weight: 900;
  font-family: 'Merriweather', serif;
  background-color: #0B8BBB;
  width: max-content;
  padding: 10px;
  margin-left: 40%;
 
}
.PrivacyPolicy-intro {
    margin-top: 20px;
  }
  
.PrivacyPolicy-text {
    text-align: justify;
    font-size: 16px; /* Adjust the size as necessary */
    color: #000000; /* Adjust the color for readability */
    line-height: 1.6; /* Good for paragraph readability */
    margin-bottom: 5px;
  }
  .PrivacyPolicy-informationCollection {
    margin-top: 20px;
  }
  
  .PrivacyPolicy-subheading {
    font-size: 25px;
    color: #000000;
    margin-bottom: 10px;
    color:#E8BB2D;
    text-transform: uppercase;
  }
  
  .PrivacyPolicy-list {
    list-style-type: none; /* Removes default list styling */
    padding-left: 0; /* Aligns with the rest of the content */
    margin-top: 10px;
  }
  
  .PrivacyPolicy-list li {
    margin-bottom: 10px; /* Spacing between list items */
    line-height: 1.6; /* Enhances readability */
    text-align: justify;
  }
  
  .PrivacyPolicy-list strong {
    font-weight: bold; /* Emphasizes the category titles */
  }
  .PrivacyPolicy-usage {
    margin-top: 20px;
  }
  .PrivacyPolicy-sharing {
    margin-top: 20px;
  }
  .PrivacyPolicy-dataRetention {
    margin-top: 20px;
  }
  .PrivacyPolicy-privacyRights {
    margin-top: 20px;
  }
  .PrivacyPolicy-dataSecurity {
    margin-top: 20px;
  }
  .PrivacyPolicy-policyChanges {
    margin-top: 20px;
  }
  .PrivacyPolicy-contactLink {
    color: #1a0dab; /* Standard link color, adjust if needed */
    text-decoration: none; /* Removes underline, adjust if needed */
  }
  .PrivacyPolicy-contactLink:hover{
    color: rgb(255, 255, 255);
    background-color: #0B8BBB;
    font-weight: 900;
  }
  
  .PrivacyPolicy-contactLink:hover,
  .PrivacyPolicy-contactLink:focus {
    text-decoration: underline; /* Adds underline on hover for better user interaction */
    cursor: pointer;
  }
  .PrivacyPolicy-contact {
    margin-top: 20px;
    margin-bottom: 40px; /* Depending on your layout, you might want extra space at the bottom */
  }
  
  /* Styles for the contact link for emphasis and clarity */
  .PrivacyPolicy-contactLink {
    color: #1a0dab; /* Adjust if needed to suit your brand's color scheme */
    text-decoration: none; /* Option to remove underline */
  }
  
  .PrivacyPolicy-contactLink:hover,
  .PrivacyPolicy-contactLink:focus {
    text-decoration: underline; /* Reintroduce underline on hover/focus for accessibility */
    cursor: pointer;
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Class for the animation */
  .PP-animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Apply the animation class to all section containers with a staggered delay */
  .PrivacyPolicy-intro {
    animation-delay: 0.2s;
  }
  
  .PrivacyPolicy-informationCollection {
    animation-delay: 0.4s;
  }
  
  .PrivacyPolicy-usage {
    animation-delay: 0.6s;
  }
  
  .PrivacyPolicy-list {
    animation-delay: 0.8s;
  }
  
  .PrivacyPolicy-sharing {
    animation-delay: 1s;
  }
  
  .PrivacyPolicy-dataRetention {
    animation-delay: 1.2s;
  }
  
  .PrivacyPolicy-privacyRights {
    animation-delay: 1.4s;
  }
  
  
  
  /* Ensure all animated elements have the animation class */
  .PrivacyPolicy-intro,
  .PrivacyPolicy-informationCollection,
  .PrivacyPolicy-usage,
  .PrivacyPolicy-list,
  .PrivacyPolicy-sharing,
  .PrivacyPolicy-dataRetention,
  .PrivacyPolicy-privacyRights
   {
    will-change: opacity, transform; /* Optimize the properties that will change during animations */
    animation: fadeInUp 0.8s ease-in-out forwards; /* Apply the animation */
  }
  @media screen and (max-width: 768px) {

    .PrivacyPolicy-container {
      display: flex;
      flex-direction: column; 
     align-items: center;
     padding: 5px;
    }
    .PrivacyPolicy-heading {
      font-family: 'Crimson Pro', serif;
      font-size: 24px;
      color: #0B8BBB;
      margin-bottom: 5px;
      /* Additional styling */
    }
    .PrivacyPolicy-introduction {
      margin-top: 5px;
      
      /* Other styles for the introduction section container */
    }
    .PrivacyPolicy-subheading {
      font-size: 13px;
      margin-bottom: 1px;
      text-align: center;
      color: #E8BB2C;
      text-transform: uppercase;
      /* Additional subheading styles */
    }
    .PrivacyPolicy-paragraph {
      font-size: 11px;
      line-height: 1.2;
      color: #000000;
      text-align: justify;
      /* Styles for your paragraph text */
    }
    .PrivacyPolicy-acceptance {
      margin-top: 5px;
      /* Additional styles specific to the acceptance section if needed */
    }
    .PrivacyPolicy-description {
      margin-top: 5px;
      /* Additional styles for the description section */
    }
    .PrivacyPolicy-Strong{
      text-transform: capitalize;
      font-size: 10px;
   }
   .PrivacyPolicy-list li {
    margin-bottom: 4.5px;
    text-align: justify;
    font-size: 10px;
    /* Style each list item for better readability */
  }
  .PrivacyPolicy-list {
    margin-top: 5px;
    margin-left: 15px;
    /* Styles for list indentation, bullet style, etc. */
  }
  .PrivacyPolicy-termination {
    margin-top: 5px;
    /* Additional styles for the termination section if needed */
  }

.PrivacyPolicy-userObligations {
    margin-top: 5px;
    /* Additional styles for the user obligations section if needed */
  }
  .PrivacyPolicy-intellectualProperty {
    margin-top: 5px;
    /* Additional styles for the intellectual property rights section if needed */
  }
  .PrivacyPolicy-disclaimers {
    margin-top: 5px;
    /* Additional styles for the disclaimers and limitations section if needed */
  }
  .PrivacyPolicy-generalTerms {
    margin-top: 5px;
    /* Additional styles for the general terms section if needed */
  }
  .PrivacyPolicy-text{
    
      text-align: justify;
      font-size: 9px; /* Adjust the size as necessary */
      color: #000000; /* Adjust the color for readability */
      line-height: 1.6; /* Good for paragraph readability */
      margin-bottom: 0px;
    
  }
   
  .PrivacyPolicy-effectiveDate {
    font-size: 0.5em;
   
    color: #ffffff; /* Choose a color that fits your site's design */
    margin-bottom: 2px;
    font-weight: 900;
    font-family: 'Merriweather', serif;
    background-color: #0B8BBB;
    width: max-content;
    padding: 5px;
    margin-left: 05%;
   
  }
  .PrivacyPolicy-intro {
    margin-top: 5px;
  }
  
  }