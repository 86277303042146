@media   (max-width: 768px){
    .Contact-content-wrapper {
        padding: 5px; /* Adding some padding around the content for breathing space */
         /* A light grey background for contrast, you can change this */
        border-radius: 8px; /* Rounded corners for aesthetics */
        box-shadow: 4px 4px 6px  #0B8BBB; /* A subtle box shadow for depth */
        text-align: center; /* Centering the text */
       display: flex;
       flex-direction: column;
        
    }
    
    .Contact-content-wrapper h1 {
        font-size: 14px; /* Adjust as needed */
        margin-bottom: 2px; /* Space between the h1 and p */
        color: rgb(11, 139, 187); /* Dark color for the headline */
        
    }
    
    .Contact-content-wrapper p {
        font-size: 12px; /* Adjust as needed */
        color: #555; /* Dark grey color for the paragraph */
        line-height: 1.5; /* Improved readability for the paragraph */
        font-weight: 900;
    }
    .Contact-card h1 {
        font-size: 15px;
        color: rgb(255, 255, 255);
    }
    .Contact-card {
        order: 1;
        flex: 1;
        padding: 5px;
        margin-top: 4px;
        border-radius: 8px;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
        text-align: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease; 
        background-color: hwb(196 4% 27%);
        
    }
    .Contact-card p {
        font-size: 10px;
        margin-bottom: 5px;
        color: rgb(255, 255, 255);
        font-weight: 100;
    }
    .Contact-cards-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;  /* or any desired height */
        gap: 0px     ;
      
    }
    .ContactUSemailLink {
        display: flex;       /* Align items side by side */
        margin-left: 80px;
        cursor: pointer;     /* Change cursor to pointer to indicate it's clickable */
        color: white;        /* Set text and SVG color to white */
        text-decoration: none; 
       
    }
    .ContactUSemailIcon {
        fill: currentColor;  /* SVG will take the color of its parent */
        transition: transform 0.3s ease, fill 0.3s ease; /* Smooth transitions */
        height: 1rem;
    }
    .ContactUSemailText {
        margin-left: 5px;   /* Space between the SVG and the text */
        transition: color 0.3s ease; /* Smooth transition for the text color */
        font-weight: 900;
        font-size: 10px;
    }
    .Contact-card:hover {
        transform: translateY(-10px); /* Moves the card slightly upwards on hover */
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Increases the depth of the shadow for a lifted effect */
    }
    form input, form textarea {
        width: 80%;
        padding: 2px;
        margin:  2px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    
form button {
    padding: 6px 12px;
    background-color:#ffffff;
    color: #0B8BBB;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 900;
}
form button:hover{
    color: white;
    background-color: #0B8BBB;
    box-shadow: 4px 4px 6px  #000000;
}
.Contact-icon-link {
    text-decoration: none;  /* Removes the underline from the links */
    color: inherit;  /* Inherits the color from parent. Useful if you change the icon color on hover */
   
}
.Contact-icon-link svg{
    fill: white;
    height:1.2rem ;
}
.Contact-icon-link svg:hover{
    fill: black;
}
.Contact-social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;  /* Adjust the spacing between icons as needed */
    margin-top: 10px;  /* Optional: Add space between the text and the icons */
}
.ContactUScard1{
    margin-bottom: 3%;
}
    
}