@media screen and (max-width: 767px){
    /* 1st frame */
    .BusinessConsultancy-heading {
        text-align: center;
        margin-top: 40vh; /* Vertically centering it in the viewport */
        font-size: 1.5rem;
        color: #0B8BBB;
        font-weight: bold;
        text-transform: uppercase;
        background-color: #E8BB2C;
        width: 100%;
        outline: 2px solid white; /* Adjust thickness if needed */
        text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
      }
      #Layer_2{
        margin-top: 0px;
      }
      .BusinessConsultancy-all-flex{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px ;
      }
      .BusinessConsultancy-contentFlex svg{
        height: 1.5rem;
        
      }
      .BusinessConsultancy-contentFlex {
        display: flex;
        margin-top: 20%;
        justify-content: center;
        align-items: center; /* To space out the heading and video */
        gap: 10%  ;
        
       
         /* Optional padding for spacing */
      }
      .IFRAME_VIDEO_BUSINESS_CONSULTANCY{
        margin-top: 3%;
        
        border-radius: 5%;
        width: 100%;
        height: 40vh;
      }
      
.BusinessConsultancy-video {
    width: 80vw; /* You can adjust this based on your design preference */
    height: 25vh;
    align-items: center;
    margin-top: 10%;
    border-radius: 10px;
  }
  /* 2ndframe */
  .frame2nd{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px     ;
  }
  .BusinessConsultancy-Onlinewebinar-2ndframe {
 
    width: auto;
    height: 15vh;  /* or whatever maximum width you desire */
    
    display: block;
  }
  .BusinessConsultancy-subheading-2ndframe{
    text-align: center;
    margin-top: 0px;
    font-size: 25px;
    color: #0B8BBB;
    text-transform: uppercase;
    
  }
  /* 3rdframe */
  .BusinessConsultancyCircleContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;  /* Set to viewport height to center the circle */
    
   
  
  }
  .BusinessConsultancyCirclesales{
    position: absolute;
    width: 23vw;  /* Adjust as needed */
    height: 15vh; /* Adjust as needed */
    background-color: #0B8BBB;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  top: 7%;
  left: 39%;
  
  
   
  }
  .BusinessConsultancyTextsales{
    font-size: 12px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add some spacing between the text and the link */
    color: white;
  }
  .BusinessConsultancyCircleBrand{
    position: absolute;
    width: 23vw;  /* Adjust as needed */
    height: 15vh; /* Adjust as needed */
    background-color: #0B8BBB;
    border-radius: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    top: 15%;
    left: 15%;
  
  }
  .BusinessConsultancyTextBrand{
    font-size: 12px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add some spacing between the text and the link */
    color: white;
  }
  .BusinessConsultancyCircleAI{
    position: absolute;
    width: 23vw;  /* Adjust as needed */
    height: 15vh; /* Adjust as needed */
    background-color: #0B8BBB;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  top: 15%;
  left: 62%;
   
  }
  .BusinessConsultancyTextAI{
    font-size: 12px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add some spacing between the text and the link */
    color: white;
  }
  .BusinessConsultancyCircleFinance{
    width: 23vw;  /* Adjust as needed */
    height: 15vh; /* Adjust as needed */
    background-color: #0B8BBB;
    border-radius: 50%;
    display: flex;
    text-align: center;
    align-items: center;
   position: absolute;
   top: 15%;
  left: 59%;
  }
  .BusinessConsultancyTextFinance{
    font-size: 10px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add some spacing between the text and the link */
    color: white;
  }
  .BusinessConsultancyCircleMarketing{
    width: 23vw;  /* Adjust as needed */
    height: 15vh; /* Adjust as needed */
    
    background-color: #0B8BBB;
    border-radius: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    position: absolute;
    top: 15%;
   left: 9%;
   
  }
  .BusinessConsultancyTextMarketing{
    font-size: 10px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add some spacing between the text and the link */
    color: white;
  }
  .BusinessConsultancyCircle {
    width: 35vw;  /* Adjust as needed */
    height: 25vh; /* Adjust as needed */
    background-color: goldenrod;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25%;
    left: 34%;
    
  }
  .BusinessConsultancyText {
    font-size: 18px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add some spacing between the text and the link */
    color: white;
    display: flex; 
    
  }
  .BusinessConsultancyLink {
    text-decoration: none;
    color: black;
    font-size: 16px; /* Adjust font size as needed */
    position: relative;  /* Set the link's position property to relative */
    transition: all 0.3s;  /* Smooth transition for any changes */
    padding-right: 20px;
    /* Add padding to prevent the text and arrow from being too close */
    
    
  }
  
 }
 @media screen and (min-width: 768px) and (max-width: 1025px) {
  .BusinessConsultancy-all-flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px ;
  }
  .BusinessConsultancy-video {
    width: 60%; /* You can adjust this based on your design preference */
    height: auto;
    align-items: center;
    margin-top: 10%;
    border-radius: 10px;
  }
  .BusinessConsultancy-subheading-2ndframe{
    text-align: center;
    margin-top: 30%;
    font-size: 25px;
    color: #0B8BBB;
    text-transform: uppercase;
    
  }
  .BusinessConsultancy-Onlinewebinar-2ndframe {
 
    width: 100%;
    max-width: 200px;  /* or whatever maximum width you desire */
    margin-top: 25%;
    display: block;
  }
  .BusinessConsultancyTextsales{
    font-size: 16px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add some spacing between the text and the link */
    color: white;
  }
  .BusinessConsultancyTextAI{
    font-size: 20px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add some spacing between the text and the link */
    color: white;
  }
  .BusinessConsultancyTextMarketing{
    font-size: 16px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Add some spacing between the text and the link */
    color: white;
  }
  
.BusinessConsultancyText {
  font-size: 1.7rem; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some spacing between the text and the link */
  color: white;
  display: flex; 
  
}
.BusinessConsultancyTextFinance{
  font-size: 20px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some spacing between the text and the link */
  color: white;
}
.BusinessConsultancyTextBrand{
  font-size: 20px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add some spacing between the text and the link */
  color: white;
}


.BusinessConsultancyLink {
  text-decoration: none;
  color: black;
  font-size: 20px; /* Adjust font size as needed */
  position: relative;  /* Set the link's position property to relative */
  transition: all 0.3s;  /* Smooth transition for any changes */
  padding-right: 20px;
  font-weight: 600;
  /* Add padding to prevent the text and arrow from being too close */
  
}
.BusinessConsultancyCirclesales{
  position: absolute;
  width: 17vw;  /* Adjust as needed */
  height: 20vh; /* Adjust as needed */
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
top: 7%;
left: 44%;

 
}
.BusinessConsultancyCircleMarketing{
  width: 17vw;  /* Adjust as needed */
  height: 20vh; /* Adjust as needed */
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  margin-top: 150px;
 
}
.BusinessConsultancyCircleAI{
  position: absolute;
  width: 17vw;  /* Adjust as needed */
  height: 20vh; /* Adjust as needed */
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
top: 15%;
left: 62%;
 
}
.BusinessConsultancyCircleBrand{
  position: absolute;
  width: 17vw;
  height: 20vh;
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  top: 15%;
  left: 25%;

}
.BusinessConsultancyCircleFinance{
  width: 17vw;  /* Adjust as needed */
  height: 20vh; /* Adjust as needed */
  background-color: #0B8BBB;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  margin-top: 150px;
  margin-left: 50px;
}
 }