/* CancellationPolicy.css */

.CancellationPolicy-container {
    padding: 10px;
    width: 100vw;
    display: flex;
    flex-direction: column;
   
    
  }
  
  .CancellationPolicy-heading {
    
    text-align: center;
    margin-bottom: 0.2em;
    color: rgb(11, 141, 188);
  }
 .CancellationPolicy-headingSize{
    font-size: 50px;
 }
  
  
  .CancellationPolicy-effectiveDate {
    font-size: 1em;
    
    margin-bottom: 1em;
    color: #ffffff; /* A subtle color to differentiate it from the main text */
    font-family: 'Merriweather', serif; /* Example font, can be set globally or per component */
    background-color: #0B8BBB;
    width: max-content;
    padding: 10px;
    margin-left: 40%;
    
  }
  
  
  
  .CancellationPolicy-text {
   text-align: justify;
    line-height: 1.6;
    color: #333; /* Opt for a readable, dark color for text */
    margin-bottom: 1em;
  }
  .CancellationPolicy-textSize{
    font-size: 20px;
  }
  .CancellationPolicy-clientCancellation {
    margin-top: 20px;
  }
  
  /* You could use the same subheading styles as in the Privacy Policy if it fits */
  .CancellationPolicy-subheading {
    font-size: 1.5em;
    color: #E7B72B;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .CancellationPolicy-subheadingSize{
    font-size: 40px ;
  }
  .CancellationPolicy-contactLink {
    color: #0d41ee; /* Standard link color */
    text-decoration: none; /* Optional: Removes underline from links */
  }
  .CancellationPolicy-contactLink:hover {
    color: #ffffff; /* Standard link color */
    text-decoration: underline; /* Optional: Removes underline from links */
    background-color: #0B8BBB;
    font-weight: 900;
    font-family: 'Merriweather', serif; /* Example font, can be set globally or per component */
    
  }
  
  .CancellationPolicy-contactLink:hover,
  .CancellationPolicy-contactLink:focus {
    text-decoration: underline; /* Adds underline on hover for better accessibility */
    cursor: pointer;
  }
  .CancellationPolicy-refunds {
    margin-top: 20px;
  }
  .CancellationPolicy-modifications {
    margin-top: 10px;
  }
  .CancellationPolicy-contactLink {
    color: #1a0dab; /* Adjust the color to match your branding, if necessary */
    text-decoration: none; /* Option to remove underline */
    margin-left: 20px;
  }
  
  .CancellationPolicy-contactLink:hover,
  .CancellationPolicy-contactLink:focus {
    text-decoration: underline; /* Adds underline on hover for better accessibility */
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {  .CancellationPolicy-heading {
    
    text-align: center;
    margin-bottom: 0.2em;
    color: rgb(11, 141, 188);
  }
 .CancellationPolicy-headingSize{
    font-size: 17px;
 }
 .CancellationPolicy-effectiveDate {
  font-size: 0.5em;
  font-weight: 900;
  margin-bottom: 1em;
  color: #ffffff; /* A subtle color to differentiate it from the main text */
  font-family: 'Merriweather', serif; /* Example font, can be set globally or per component */
  background-color: #0B8BBB;
  width: max-content;
  padding: 5px;
  margin-left: 30%;
  
}
.CancellationPolicy-text {
  text-align: justify;
   line-height: 1.6;
   color: #333; /* Opt for a readable, dark color for text */
   margin-bottom: 0.1em;
 }
 .CancellationPolicy-textSize{
   font-size: 0.7rem;
 }
 .CancellationPolicy-subheading {
  font-size: 1.5em;
  color: #E7B72B;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.CancellationPolicy-subheadingSize{
  font-size: 19px ;
}
.CancellationPolicy-subheading-CodingVisuals{
  font-size: 18px;
}
.CancellationPolicy-text-CV{
  font-size: 10px;
}
.CancellationPolicy-list-CV li{
  font-size: 10px ;
}
.CancellationPolicy-list-ForceMajeure{
  display: none;
}
.CancellationPolicy-refunds-CV{
  font-size: 18px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.CancellationPolicy-text-refund-cv{
  font-size: 10px;
}
.CancellationPolicy-refunds{
  margin-top: 4px;
}
.CancellationPolicy-subheading-modification{
  font-size: 18px;
  margin-bottom: 2px;
}
}
 