body {
    margin: 0;
    padding: 0;
    font-family: "Arial", sans-serif;
    overflow: hidden; 
  }
  
  .scroll-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; 
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .section {
    height: 100vh;
    scroll-snap-align: start;
  }
  .pagination {
    position: fixed;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
  }
  
  .pagination-dot {
    width: 10px;
    height: 10px;
    background: #6e6e6e;
    border-radius: 50%;
    transition: background 0.3s;
    cursor: pointer;
  }
  
  .pagination-dot:hover {
    background: #555;
  }
  
  .pagination-dot.active {
    background: gold;
  }
  /* styling suru ho rhi hai  */
  
/* styling suru ho rhi h */
/* first frame */
.Solution-cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;  
  align-items: center;
  width: 100%;  
  padding: 0 20px; 
  box-sizing: border-box;
}

.Solution-card {
  flex: 1; 
  max-width: calc(100% - 40px); 
  height: 600px ;
  box-sizing: border-box;
  padding: 20px;
  margin: 10px;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  
  
  
}
.Solution-card.animate {
  animation: slideFadeInUpSolution-card 0.7s ease-out forwards;
}
@keyframes slideFadeInUpSolution-card {
  from {
      opacity: 0;
      transform: translateY(50px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
.Solution-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.Solution-FirstCardText {
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
  padding: 30px;
  flex-basis: 70%; /* Take up half the space */
  width: 60%;
  z-index: 1;
}
.Solution-FirstCardText:hover {
  color: rgb(0, 0, 0); /* Change text color to white on hover */
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.3); /* Add a white shadow on hover */
  border: 0px;
; 
}
.Solution-FirstCardHeader {
  font-size: 40px; /* Adjust the font size as needed */
  color: #0B8BBB; /* Adjust the font color as needed */
  margin-top: 160px ; /* Adjust the margin as needed */
  text-align: center; /* Centers the text */
  font-weight: bold;
  /* Add any other desired styling here */
  display: block; /* So it's above the text */
  margin-bottom: 20px;
  
  
  padding: 2px;
  
}
.Solution-FirstCardAI {
  position: fixed;
  width: 22%; 
  border-radius: 8px;
  object-fit: cover;
  height: auto;
  /* Align the image to the top of its container */
  margin-left: 820px;
  
  z-index: 0;
  transition: transform 0.3s, box-shadow 0.3s; 
 
}
.SolutionFIRSTFRAMEAIBUTTON:hover .Insights-arrow {
  margin-left: 12px; /* On hover, move the arrow slightly to the right for a dynamic effect */
}
.SolutionFIRSTFRAMEAIBUTTON:hover {
  background-color: #000000;
  color: #E8BB2C;
  border-color: #ccc;
}
.Solution-AIarrow {
  margin-left: 10px;
  font-size: 18px; /* Larger arrow for prominence */
  transition: all 0.3s;
}

.SolutionFIRSTFRAMEAIBUTTON {
  position: absolute;
  top: 70%;
  right: 10%;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: #000000;
  font-size: 15px;
  font-weight: 900;
  cursor: pointer;
  background-color: #E8BB2C; /* Light gray background */
  border: 2px solid #ddd; /* Slightly darker gray for the border */
  border-radius: 25px;
  transition: all 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
 
}
.Solution-modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.Solution-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.Solution-close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.Solution-close-btn:hover {
  color: black;
}
.Solution-Phone-GmailOnly{
  display: flex;
  flex-direction: column;
  
  align-items: center;
}
.Solution-contact-option {
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer; 
  gap: 5px ;
}
.Solution-contact-option a{
  text-decoration: none;
  color: #0B8BBB;
}
.Solution-contact-option a:hover{
  text-decoration: underline;
}

.Solution-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  fill: #0B8BBB;  /* Adjust as needed */
}
.SolutionAIContainer{
  display: flex;
  flex-direction: column;
  align-items: center;  /* Center align items vertically */
  gap: 10px; 
}
.Solution-FirstCardAIDraw{
  fill: transparent;
    stroke: black; /* Or any color you want for the drawing effect */
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawSVGsolutionAI 3s forwards, fillSVGsolutionAI 2s forwards 3s; 
    
    
}

@keyframes drawSVGsolutionAI {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutionAI {
  to {
      fill: black; /* Or any color you want for the filling effect */
  }
}
.Solution-FirstCardImage:hover {
  transform: scale(1.02); /* Zoom in effect on hover */
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.3); /* Add a white shadow on hover */
}


.Solution-FirstCard {
  display: flex;
  
  justify-content: flex-start; /* Horizontally center the content */
  gap: 20px;
  width: 100%; 
  /* Take the full width of its parent */
}


/* Initially set up the SVG with a transparent fill and define the stroke properties */
.Solutionrobotsvg{
  
  margin-top: 160px;
}
.Solutionrobotdrawsvg {
  fill: transparent;
  stroke: rgb(0, 0, 0);
  stroke-width: 2;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: drawSVGsolutionrobotIcon 3s forwards, fillSVGsolutionrobotIcon 2s forwards 3s ; 
}

/* The drawing animation */
@keyframes drawSVGsolutionrobotIcon {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutionrobotIcon {
  to {
      fill: gold; /* Or any color you want for the filling effect */
  }
}

/* design for button on ai solution */
/* ... (your existing styles) ... */

.SolutionModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
}

.SolutionModalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.SolutionClose {
  color: #0B8BBB;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  
}

.SolutionAiEmail{
  margin-left: 80px;
  color: #0B8BBB;
  font-size: 20px;
}
.SolutionAIphone{
  margin-left: 40px;
  text-decoration: none;
  color:#E8BB2C ;
  
  
}

.SolutionAiPhone{
  margin-left: 65px;
  color: #0B8BBB;
  font-size: 20px;
  
}
.SolutionAIphone:hover{
  color: #000000;
 
}
.SolutionAIgmail{
  text-decoration: none;
  color:#E8BB2C ;
  
}
.SolutionAIgmail:hover{
  color: #000000;
}
.SolutionClose:hover,
.SolutionClose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.SolutionAI-popupstructure{
 display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}
.SolutionAI-popupstructureGmail{
  margin-right: 25px;
  display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}
.SolutionAI-mobile svg{
  
  fill: #0B8BBB;
  transition: transform 0.3s ease;
}
.SolutionAI-mobile svg:hover{
  transform: scale(1.5);
  
}
.SolutionAiEmail svg{
  fill: #0B8BBB;
  transition: transform 0.3s ease;
  
}
.SolutionAiEmail svg:hover{
  transform: scale(1.5);
}
/* The retain position animation (basically a delay to keep 
/* A reset animation to reset the SVG to its original state before looping again */

/* 2nd frame */
.Solution-SecondCardText {
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
  padding: 30px;
  
  
  width: 60%;
  z-index: 1;
}
.Solution-SecondCardText:hover {
  color: #000000; /* Change text color to white on hover */
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.3); /* Add a white shadow on hover */
  border: 0px;
  ; 
}
.Solution-SecondCardHeader {
  font-size: 40px; /* Adjust the font size as needed */
  color: #0B8BBB; /* Adjust the font color as needed */
  margin-top: 160px ; /* Adjust the margin as needed */
  text-align: center; /* Centers the text */
  font-weight: bold;
  /* Add any other desired styling here */
  display: block; /* So it's above the text */
  margin-bottom: 20px;
  
  padding: 2px;
 
  
  
}
.Solution-SecondCardAI {
  position: fixed;
  width: 22%; 
  border-radius: 8px;
  object-fit: cover;
  height: auto;
  /* Align the image to the top of its container */
  margin-left: 820px;
  
  z-index: 0;
  transition: transform 0.3s, box-shadow 0.3s; 
 
}
.Solution-SecondCardAIDraw{
  fill: transparent;
    stroke: rgb(0, 0, 0); /* Or any color you want for the drawing effect */
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawSVGsolutionAISecond 3s forwards, fillSVGsolutionAISecond 2s forwards 3s; 
}
@keyframes drawSVGsolutionAISecond {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutionAISecond {
  to {
      fill: rgb(0, 0, 0); /* Or any color you want for the filling effect */
  }
}


.Solution-SecondCard {
  display: flex;
  
  justify-content: flex-start; /* Horizontally center the content */
  gap: 20px;
  width: 100%; 
  /* Take the full width of its parent */
}


/* Initially set up the SVG with a transparent fill and define the stroke properties */
.Solutionranking-starsvg{
  
  margin-top: 160px;
}
.Solutionranking-stardrawsvg{
  fill: transparent;
  stroke: rgb(0, 0, 0);
  stroke-width: 2;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: drawSVGsolutionBrand-StrategyIcon 3s forwards, fillSVGsolutionBrand-StrategyIcon 2s forwards 3s ; 
}
/* The drawing animation */
@keyframes drawSVGsolutionBrand-StrategyIcon {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutionBrand-StrategyIcon {
  to {
      fill: gold; /* Or any color you want for the filling effect */
  }
}
/* design for button on ai solution */
/* ... (your existing styles) ... */
.SolutionAISecondButton{
  padding: 10px;
  margin-left: 800px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 215, 0);
  border-radius: 20px;
  font-weight: 900;
  transition: transform 0.3s ease;
  font-size: 20px;

}
.SolutionAISecondButton:hover{
  color: rgb(255, 215, 0);
  background-color: rgb(0, 0, 0);
  cursor: pointer;
  transform: scale(1.1);

}
.SolutionSecondModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
}

.SolutionSecondModalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.SolutionSecondClose {
  color: #0B8BBB;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  
}

.SolutionAiSecondEmail{
  margin-left: 80px;
  color: #0B8BBB;
  font-size: 20px;
}
.SolutionSecondAIphone{
  margin-left: 40px;
  text-decoration: none;
  color:#E8BB2C ;
  
  
}

.SolutionSecondAiPhone{
  margin-left: 65px;
  color: #0B8BBB;
  font-size: 20px;
  
}
.SolutionSecondAIphone:hover{
  color: #000000;
 
}
.SolutionSecondAIgmail{
  text-decoration: none;
  color:#E8BB2C ;
  
}
.SolutionSecondAIgmail:hover{
  color: #000000;
}
.SolutionSecondClose:hover,
.SolutionSecondClose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.SolutionSecondAI-popupstructure{
 display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}
.SolutionSecondAI-popupstructureGmail{
  margin-right: 25px;
  display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}
.SolutionSecondAI-mobile svg{
  
  fill: #0B8BBB;
  transition: transform 0.3s ease;
}
.SolutionSecondAI-mobile svg:hover{
  transform: scale(1.5);
  
}
.SolutionAiSecondEmail svg{
  fill: #0B8BBB;
  transition: transform 0.3s ease;
  
}
.SolutionAiSecondEmail svg:hover{
  transform: scale(1.5);
}

/* 3rd frame */
/* starting */
.Solution-ThirdCardText {
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
  padding: 30px;
  
  
  width: 60%;
  z-index: 1;
}
.Solution-ThirdCardText:hover {
  color: #000000; /* Change text color to white on hover */
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.3); /* Add a white shadow on hover */
  border: 0px;
  
}
.Solution-ThirdCardHeader {
  font-size: 40px; /* Adjust the font size as needed */
  color: #0B8BBB; /* Adjust the font color as needed */
  margin-top: 100px ; /* Adjust the margin as needed */
  text-align: center; /* Centers the text */
  font-weight: bold;
  /* Add any other desired styling here */
  display: block; /* So it's above the text */
  margin-bottom: 20px;
  
  padding: 2px;
  
  
}
.Solution-ThirdCardAI {
  position: fixed;
  width: 22%; 
  border-radius: 8px;
  object-fit: cover;
  height: auto;
  /* Align the image to the top of its container */
  margin-left: 750px;

  z-index: 0;
  transition: transform 0.3s, box-shadow 0.3s; 
 
}
.Solution-ThirdCardAIDraw{
  fill: transparent;
    stroke: rgb(0, 0, 0); /* Or any color you want for the drawing effect */
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawSVGsolutionAIThird 3s forwards, fillSVGsolutionAIThird 2s forwards 3s; 
}
@keyframes drawSVGsolutionAIThird {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutionAIThird {
  to {
      fill: rgb(0, 0, 0); /* Or any color you want for the filling effect */
  }
}


.Solution-ThirdCard {
  display: flex;
  
  justify-content: flex-start; /* Horizontally center the content */
  gap: 20px;
  width: 100%; 
  /* Take the full width of its parent */
}


/* Initially set up the SVG with a transparent fill and define the stroke properties */
.Solutiondollarbagsvg{
  
  margin-top: 100px;
}
.Solutiondollarbagdrawsvg{
  fill: transparent;
  stroke: rgb(0, 0, 0);
  stroke-width: 2;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: drawSVGsolutiondollarbagIcon 3s forwards, fillSVGsolutiondollarbagIcon 2s forwards 3s; 
}
/* The drawing animation */
@keyframes drawSVGsolutiondollarbagIcon {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutiondollarbagIcon {
  to {
      fill: gold; /* Or any color you want for the filling effect */
  }
}
/* design for button on ai solution */
/* ... (your existing styles) ... */
.SolutionAIThirdButton{
  padding: 10px;
  margin-left: 800px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 215, 0);
  border-radius: 20px;
  font-weight: 900;
  transition: transform 0.3s ease;
  font-size: 20px;

}
.SolutionAIThirdButton:hover{
  color: rgb(255, 215, 0);
  background-color: rgb(0, 0, 0);
  cursor: pointer;
  transform: scale(1.1);

}
.SolutionThirdModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
}

.SolutionThirdModalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.SolutionThirdClose {
  color: #0B8BBB;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  
}

.SolutionAiThirdEmail{
  margin-left: 80px;
  color: #0B8BBB;
  font-size: 20px;
}
.SolutionThirdAIphone{
  margin-left: 40px;
  text-decoration: none;
  color:#E8BB2C ;
  
  
}

.SolutionThirdAiPhone{
  margin-left: 65px;
  color: #0B8BBB;
  font-size: 20px;
  
}
.SolutionThirdAIphone:hover{
  color: #000000;
 
}
.SolutionThirdAIgmail{
  text-decoration: none;
  color:#E8BB2C ;
  
}
.SolutionThirdAIgmail:hover{
  color: #000000;
}
.SolutionThirdClose:hover,
.SolutionThirdClose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.SolutionThirdAI-popupstructure{
 display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}
.SolutionThirdAI-popupstructureGmail{
  margin-right: 25px;
  display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}
.SolutionThirdAI-mobile svg{
  
  fill: #0B8BBB;
  transition: transform 0.3s ease;
}
.SolutionThirdAI-mobile svg:hover{
  transform: scale(1.5);
  
}
.SolutionAiThirdEmail svg{
  fill: #0B8BBB;
  transition: transform 0.3s ease;
  
}
.SolutionAiThirdEmail svg:hover{
  transform: scale(1.5);
}
/* Fourth Frame */
.Solution-FourthCardText {
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
  padding: 30px;
  font-weight: 400;
  
  width: 60%;
  z-index: 1;
}
.Solution-FourthCardText:hover {
  color: #000000; /* Change text color to white on hover */
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.3); /* Add a white shadow on hover */
  border: 0px;
  
  
}
.Solution-FourthCardHeader {
  font-size: 40px; /* Adjust the font size as needed */
  color: #0B8BBB; /* Adjust the font color as needed */
  margin-top: 100px ; /* Adjust the margin as needed */
  text-align: center; /* Centers the text */
  font-weight: bold;
  /* Add any other desired styling here */
  display: block; /* So it's above the text */
  margin-bottom: 20px;
  
  padding: 2px;
  
  
}
.Solution-FourthCardAI {
  position: fixed;
  width: 22%; 
  border-radius: 8px;
  object-fit: cover;
  height: auto;
  /* Align the image to the top of its container */
  margin-left: 850px;

  z-index: 0;
  transition: transform 0.3s, box-shadow 0.3s; 
 
}
.Solution-FourthCardAIDraw{
  fill: transparent;
    stroke: rgb(0, 0, 0); /* Or any color you want for the drawing effect */
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawSVGsolutionAIFourth 3s forwards, fillSVGsolutionAIFourth 2s forwards 3s; 
}
@keyframes drawSVGsolutionAIFourth {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutionAIFourth {
  to {
      fill: rgb(0, 0, 0); /* Or any color you want for the filling effect */
  }
}


.Solution-FourthCard {
  display: flex;
  
  justify-content: flex-start; /* Horizontally center the content */
  gap: 20px;
  width: 100%; 
  /* Take the full width of its parent */
}


/* Initially set up the SVG with a transparent fill and define the stroke properties */
.SolutionBusinessbagsvg{
  
  margin-top: 100px;
}
.SolutionBusinessbagdrawsvg{
  fill: transparent;
  stroke: rgb(0, 0, 0);
  stroke-width: 2;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: drawSVGsolutionBusinessbagIcon 3s forwards, fillSVGsolutionBusinessbagIcon 2s forwards 3s; 
}
/* The drawing animation */
@keyframes drawSVGsolutionBusinessbagIcon {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutionBusinessbagIcon {
  to {
      fill: gold; /* Or any color you want for the filling effect */
  }
}
/* design for button on ai solution */
/* ... (your existing styles) ... */
.SolutionAIFourthButton{
  padding: 10px;
  margin-left: 800px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 215, 0);
  border: 1px solid white;
  border-radius: 20px;
  font-weight: 900;
  transition: transform 0.3s ease;
  font-size: 20px;

}
.SolutionAIFourthButton:hover{
  color: rgb(255, 215, 0);
  background-color: rgb(0, 0, 0);
  cursor: pointer;
  transform: scale(1.1);

}
.SolutionFourthModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
}

.SolutionFourthModalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.SolutionFourthClose {
  color: #0B8BBB;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  
}

.SolutionAiFourthEmail{
  margin-left: 80px;
  color: #0B8BBB;
  font-size: 20px;
}
.SolutionFourthAIphone{
  margin-left: 40px;
  text-decoration: none;
  color:#E8BB2C ;
  
  
}

.SolutionFourthAiPhone{
  margin-left: 65px;
  color: #0B8BBB;
  font-size: 20px;
  
}
.SolutionFourthAIphone:hover{
  color: #000000;
 
}
.SolutionFourthAIgmail{
  text-decoration: none;
  color:#E8BB2C ;
  
}
.SolutionFourthAIgmail:hover{
  color: #000000;
}
.SolutionFourthClose:hover,
.SolutionFourthClose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.SolutionFourthAI-popupstructure{
 display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}
.SolutionFourthAI-popupstructureGmail{
  margin-right: 25px;
  display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}
.SolutionFourthAI-mobile svg{
  
  fill: #0B8BBB;
  transition: transform 0.3s ease;
}
.SolutionFourthAI-mobile svg:hover{
  transform: scale(1.5);
  
}
.SolutionAiFourthEmail svg{
  fill: #0B8BBB;
  transition: transform 0.3s ease;
  
}
.SolutionAiFourthEmail svg:hover{
  transform: scale(1.5);
}

/* fifth */
.Solution-FifthCardText {
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
  padding: 30px;
  
  
  width: 60%;
  z-index: 1;
}
.Solution-FifthCardText:hover {
  color: black ; /* Change text color to white on hover */
  box-shadow: 0px 5px 15px rgba(255, 255, 255, 0.3); /* Add a white shadow on hover */
  border: 0px;
  
  
}
.Solution-FifthCardHeader {
  font-size: 40px; /* Adjust the font size as needed */
  color: #0B8BBB; /* Adjust the font color as needed */
 
  text-align: center; /* Centers the text */
  font-weight: bold;
  /* Add any other desired styling here */
  display: block; /* So it's above the text */
  
 
  padding: 2px;
  
  
}
.Solution-FifthCardAI {
  position: absolute;
  width: 45%; 
  top: 0%;
  border-radius: 8px;
  object-fit: cover;
  height: auto;
  /* Align the image to the top of its container */
  margin-left: 760px;
 
  
  transition: transform 0.3s, box-shadow 0.3s; 
 
}
.Solution-FifthCardAIDraw{
  fill: transparent;
    stroke: rgb(0, 0, 0); /* Or any color you want for the drawing effect */
    stroke-width: 2;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation: drawSVGsolutionAIFifth 3s forwards, fillSVGsolutionAIFifth 2s forwards 3s ; 
}
@keyframes drawSVGsolutionAIFifth {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutionAIFifth {
  to {
      fill: rgb(0, 0, 0); /* Or any color you want for the filling effect */
  }
}


.Solution-FifthCard {
  display: flex;
  
  justify-content: flex-start; /* Horizontally center the content */
  gap: 20px;
  width: 100%; 
  /* Take the full width of its parent */
}


/* Initially set up the SVG with a transparent fill and define the stroke properties */
/* .SolutionCodingsvg{
  
  margin-top: 100px;
} */
.SolutionCodingdrawsvg{
  fill: transparent;
  stroke: rgb(0, 0, 0);
  stroke-width: 2;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: drawSVGsolutionCodingIcon 3s forwards, fillSVGsolutionCodingIcon 2s forwards 3s; 
}
/* The drawing animation */
@keyframes drawSVGsolutionCodingIcon {
  to {
      stroke-dashoffset: 0;
  }
}

/* The filling animation */
@keyframes fillSVGsolutionCodingIcon {
  to {
      fill: gold; /* Or any color you want for the filling effect */
  }
}
/* design for button on ai solution */
/* ... (your existing styles) ... */
.SolutionAIFifthButton{
  padding: 10px;
  margin-left: 800px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 215, 0);
  border: 1px solid white;
  border-radius: 20px;
  font-weight: 900;
  transition: transform 0.3s ease;
  font-size: 20px;

}
.SolutionAIFifthButton:hover{
  color: rgb(255, 215, 0);
  background-color: rgb(0, 0, 0);
  cursor: pointer;
  transform: scale(1.1);

}
.SolutionFifthModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
}

.SolutionFifthModalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.SolutionFifthClose {
  color: #0B8BBB;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  
}

.SolutionAiFifthEmail{
  margin-left: 80px;
  color: #0B8BBB;
  font-size: 20px;
}
.SolutionFifthAIphone{
  margin-left: 40px;
  text-decoration: none;
  color:#E8BB2C ;
  
  
}

.SolutionFifthAiPhone{
  margin-left: 65px;
  color: #0B8BBB;
  font-size: 20px;
  
}
.SolutionFifthAIphone:hover{
  color: #000000;
 
}
.SolutionFifthAIgmail{
  text-decoration: none;
  color:#E8BB2C ;
  
}
.SolutionFifthAIgmail:hover{
  color: #000000;
}
.SolutionFifthClose:hover,
.SolutionFifthClose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.SolutionFifthAI-popupstructure{
 display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}
.SolutionFifthAI-popupstructureGmail{
  margin-right: 25px;
  display: flex;
 flex-direction: column;
 justify-content: center;
 text-align: center;
}







.SolutionFifthAI-mobile svg{
  
  fill: #0B8BBB;
  transition: transform 0.3s ease;
}
.SolutionFifthAI-mobile svg:hover{
  transform: scale(1.5);
  
}
.SolutionAiFifthEmail svg{
  fill: #0B8BBB;
  transition: transform 0.3s ease;
  
}
.SolutionAiFifthEmail svg:hover{
  transform: scale(1.5);
}
.Solution-goldcolorfifth {
  transition: color 0.3s ease, transform 0.3s ease; /* This ensures a smooth transition */
}
.Solution-FONTENLARGE{
  position: relative;
}
/* Hover state for the parent container */
.Solution-FONTENLARGE:hover .Solution-goldcolorfifth {
  color: #0B8BBB; /* Change the color to red */
  /* Enlarge the size. 1.1 means 110% of the original size. Adjust as needed. */
  font-weight: bolder;
  
}
/* Sixth frame */
section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* this will align items to the start of the container */
  height: auto; /* adjust this if necessary */
  min-height: 100px; /* or some appropriate value that suits your design */
}
.Solution-footer-container {
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 5%;
background-color: #0B8BBB;
width: 100%;
align-self: flex-start;
margin-bottom: 1px;
height: 40px;

}

.Solution-footer-item {
flex: 1;
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
}

.Solution-footer-item a {
color: #ffffff;
text-decoration: none;
transition: color 0.3s;
}

.Solution-footer-item a:hover {
color: gold;
}
.Solution-footer-item svg {
fill: white;
transition: transform 0.3s, fill 0.3s;
}
.Solution-footer-item:hover svg {
transform: scale(1.2); /* This will enlarge the SVG by 20% */
fill: gold; /* This changes the color to gold */
}

.Solution-footer-item a:hover + svg {
transform: scale(1.2);
fill: gold;
}
.Solution-secondary-container {
display: flex;
justify-content: center;
align-items: center;
padding: 1em 10%;
background-color:#0B8BBB;
width: 100%;
margin-bottom: 1px;

}

.Solution-mission{
flex: 1;
display: flex;
flex-direction: column;
align-items: center;
gap: 10px;
}
.SolutionFooterSocialMediaLinks{
  flex: 1;
display: flex;
flex-direction: column;
align-items: center;
gap: 13px ;
}
.Solution-mission h3, .SolutionFooterSocialMediaLinks h3 {
color: gold;
}
.SolutionFooterSocialMediaLinks svg{
  fill: white;
  
}
.SolutionFooterSocialMediaLinks svg:hover{
  fill: black;
}
.Solution-mission p {
color: #ffffff;
text-align: center;
margin-bottom: 50px;
font-size: 18px;
}



.Solution-copyright-section {
padding: 20px 0;
text-align: center;
background-color: #0B8BBB;  /* A light gray background */
font-size: 14px;
border-top: 1px solid #e0e0e0;
width: 100%;

margin-top: 1px;
}

.Solution-copyright-section p {
margin: 0;
color: #ffffff;  /* Slightly dark text color */
font-size: 18px;
}

.Solution-copyright-section a {
color: #ffffff;  /* Classic link color */
text-decoration: none;
margin: 0 5px;
}

.Solution-copyright-section a:hover {
text-decoration: underline;
color: gold;
}
.ThoughtoFTHEDAY{
  display: flex;
  justify-content: center;
}

/* Buttons Business Stratedgy */
.SolutionSECONDFRAMEBUSINESSBUTTON:hover .Insights-arrow {
  margin-left: 12px; /* On hover, move the arrow slightly to the right for a dynamic effect */
}
.SolutionSECONDFRAMEBUSINESSBUTTON:hover {
  background-color: #000000;
  color: #E8BB2C;
  border-color: #ccc;
}
.Solution-BUSINESSarrow {
  margin-left: 10px;
  font-size: 18px; /* Larger arrow for prominence */
  transition: all 0.3s;
}

.SolutionSECONDFRAMEBUSINESSBUTTON{
  position: absolute;
  top: 75%;
  right: 10%;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: #000000;
  font-size: 15px;
  font-weight: 900;
  cursor: pointer;
  background-color: #E8BB2C; /* Light gray background */
  border: 2px solid #ddd; /* Slightly darker gray for the border */
  border-radius: 25px;
  transition: all 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
 
}
/* Finance */
.SolutionTHIRDFRAMEFINANCEBUTTON:hover .Solution-FINANCEarrow {
  margin-left: 12px; /* On hover, move the arrow slightly to the right for a dynamic effect */
}
.SolutionTHIRDFRAMEFINANCEBUTTON:hover {
  background-color: #000000;
  color: #E8BB2C;
  border-color: #ccc;
}
.Solution-FINANCEarrow {
  margin-left: 10px;
  font-size: 18px; /* Larger arrow for prominence */
  transition: all 0.3s;
}

.SolutionTHIRDFRAMEFINANCEBUTTON{
  position: absolute;
  top: 75%;
  right: 10%;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: #000000;
  font-size: 15px;
  font-weight: 900;
  cursor: pointer;
  background-color: #E8BB2C; /* Light gray background */
  border: 2px solid #ddd; /* Slightly darker gray for the border */
  border-radius: 25px;
  transition: all 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
 
}
/* sales */
.SolutionFOURTHFRAMESALESBUTTON:hover .Solution-SALESarrow {
  margin-left: 12px; /* On hover, move the arrow slightly to the right for a dynamic effect */
}
.SolutionFOURTHFRAMESALESBUTTON:hover {
  background-color: #000000;
  color: #E8BB2C;
  border-color: #ccc;
}
.Solution-SALESarrow {
  margin-left: 10px;
  font-size: 18px; /* Larger arrow for prominence */
  transition: all 0.3s;
}

.SolutionFOURTHFRAMESALESBUTTON{
  position: absolute;
  top: 75%;
  right: 10%;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: #000000;
  font-size: 15px;
  font-weight: 900;
  cursor: pointer;
  background-color: #E8BB2C; /* Light gray background */
  border: 2px solid #ddd; /* Slightly darker gray for the border */
  border-radius: 25px;
  transition: all 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
 
}
/* Marketing */
.SolutionFIFTHFRAMEDEVBUTTON:hover .Solution-DEVarrow {
  margin-left: 12px; /* On hover, move the arrow slightly to the right for a dynamic effect */
}
.SolutionFIFTHFRAMEDEVBUTTON:hover {
  background-color: #000000;
  color: #E8BB2C;
  border-color: #ccc;
}
.Solution-DEVarrow {
  margin-left: 10px;
  font-size: 18px; /* Larger arrow for prominence */
  transition: all 0.3s;
}

.SolutionFIFTHFRAMEDEVBUTTON{
  position: absolute;
  top: 79%;
  right: 20%;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  text-decoration: none;
  color: #000000;
  font-size: 15px;
  font-weight: 900;
  cursor: pointer;
  background-color: #E8BB2C; /* Light gray background */
  border: 2px solid #ddd; /* Slightly darker gray for the border */
  border-radius: 25px;
  transition: all 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
 
}