.informative-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    background-color: #0B8BBB;
    color: rgb(255, 255, 255);
    padding: 4px;
    position: sticky;
    width: 100%; /* To ensure it spans the full width of the viewport. */
    z-index: 1001;
}

.info-item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.info-link {
    text-decoration: none;
    color: inherit;
    display: flex;            
    align-items: center;     
    gap:5px;
    transition: color 0.3s ease; /* Added for smooth color transition */
}

/* Hover effects for .info-link */
.info-link:hover {
    color: rgb(255, 215, 0); /* Text color turns gold */
    text-decoration: underline; /* Text gets underlined */
}

/* Hover effects for SVG icon inside .info-link */
.info-link:hover svg {
    transform: scale(1.1); /* Enlarges the SVG by 10% */
    fill: rgb(255, 215, 0); /* Icon color turns gold */
}

/* Transition effects */
.info-link, .info-link svg {
    transition: all 0.3s ease;
    fill: rgb(255, 255, 255);
}

.social-media-links {
    display: flex;
    gap: 10px;
}

.social-media-links svg {
    fill: rgb(255, 255, 255);
    width: 20px;
    height: 20px;
    transition: opacity 0.3s ease, fill 0.3s ease;  /* Added fill transition */
}

/* Hover effects for social media links */
.social-media-links a:hover svg {
    
    fill: black;
}
