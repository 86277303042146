body {
    margin: 0;
    padding: 0;
    font-family: "Arial", sans-serif;
    overflow: hidden; 
  }
  
  .scroll-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; 
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .section {
    height: 100vh;
    scroll-snap-align: start;
  }
  .pagination {
    position: fixed;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
  }
  
  .pagination-dot {
    width: 10px;
    height: 10px;
    background: #6e6e6e;
    border-radius: 50%;
    transition: background 0.3s;
    cursor: pointer;
  }
  
  .pagination-dot:hover {
    background: #555;
  }
  
  .pagination-dot.active {
    background: gold;
  }
  
  /* starting */
  
  .SolutionTwitter{
    display: flex;
    text-decoration: none;
    color: white;
    align-items: center;
    gap: 10px;

  }
  .SolutionTwitter:hover{
    color: #000000;
  }
.SolutionInstagram{
    display: flex;
    text-decoration: none;
    color: white;
    align-items: center;
    gap: 10px;
}
.SolutionInstagram:hover{
    color: #000000;
  }
.SolutionYoutube{
    display: flex;
    text-decoration: none;
    color: white;
    align-items: center;
    gap: 10px;
}
.SolutionYoutube:hover{
    color: #000000;
  }
.SolutionLinkedin{
    display: flex;
    text-decoration: none;
    color: white;
    align-items: center;
    gap: 10px;
}
.SolutionLinkedin:hover{
    color: #000000;
  }
.SolutionFB{
    display: flex;
    text-decoration: none;
    color: white;
    align-items: center;
    gap: 10px;
}
.SolutionFB:hover{
    color: #000000;
  }
  /* ContactUS.css */

.Contact-content-wrapper {
    padding: 20px; /* Adding some padding around the content for breathing space */
     /* A light grey background for contrast, you can change this */
    border-radius: 8px; /* Rounded corners for aesthetics */
    box-shadow: 4px 4px 6px  #0B8BBB; /* A subtle box shadow for depth */
    text-align: center; /* Centering the text */
    max-width: 1500px; /* Ensuring the content doesn't stretch too wide */
    margin: 20px auto; /* Centering the content div and adding some margin */
}

.Contact-content-wrapper h1 {
    font-size: 30px; /* Adjust as needed */
    margin-bottom: 15px; /* Space between the h1 and p */
    color: rgb(11, 139, 187); /* Dark color for the headline */
}

.Contact-content-wrapper p {
    font-size: 18px; /* Adjust as needed */
    color: #555; /* Dark grey color for the paragraph */
    line-height: 1.5; /* Improved readability for the paragraph */
    font-weight: 900;
}
.Contact-cards-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;  /* or any desired height */
    gap: 20px   ;
  
}


.Contact-card {
    flex: 1;
    padding: 20px;
    margin-top: 35px;
    border-radius: 8px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    background-color: hwb(196 4% 27%);
    
}
.Contact-card:hover {
    transform: translateY(-10px); /* Moves the card slightly upwards on hover */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Increases the depth of the shadow for a lifted effect */
}
.Contact-card h1 {
    font-size: 30px;
    color: rgb(255, 255, 255);
}

.Contact-card p {
    font-size: 16px;
    margin-bottom: 15px;
    color: rgb(255, 255, 255);
}

.Contact-social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.icon-gmail, .icon-twitter, .icon-instagram, .icon-youtube, .icon-facebook, .icon-linkedin {
    /* Placeholder for the actual icons - you might use an icon library like Font Awesome */
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #ccc; /* Placeholder background-color */
    border-radius: 50%;
}

form input, form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

form button {
    padding: 10px 15px;
    background-color:#ffffff;
    color: #0B8BBB;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 900;
}
form button:hover{
    color: white;
    background-color: #0B8BBB;
    box-shadow: 4px 4px 6px  #000000;
}
.ContactUSemailLink {
    display: flex;       /* Align items side by side */
    margin-left: 80px;
    cursor: pointer;     /* Change cursor to pointer to indicate it's clickable */
    color: white;        /* Set text and SVG color to white */
    text-decoration: none; 
   
}
.ContactUSemailIcon {
    fill: currentColor;  /* SVG will take the color of its parent */
    transition: transform 0.3s ease, fill 0.3s ease; /* Smooth transitions */
}
.ContactUSemailText {
    margin-left: 10px;   /* Space between the SVG and the text */
    transition: color 0.3s ease; /* Smooth transition for the text color */
    font-weight: 900;
}

/* Hover Effects */
.ContactUSemailLink:hover .ContactUSemailIcon {
    fill: black;           /* Change SVG color to black on hover */
    transform: scale(1.2); /* Enlarge the SVG on hover */
}

.ContactUSemailLink:hover .ContactUSemailText {
    color: black;          /* Change text color to black on hover */
}



  /*3rd card  */
.Contact-social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;  /* Adjust the spacing between icons as needed */
    margin-top: 20px;  /* Optional: Add space between the text and the icons */
}

.Contact-icon-link {
    text-decoration: none;  /* Removes the underline from the links */
    color: inherit;  /* Inherits the color from parent. Useful if you change the icon color on hover */
}
.Contact-icon-link svg{
    fill: white;
}
.Contact-icon-link svg:hover{
    fill: black;
}

/* If you want to add hover effects to change color */



  
  
  
  
  
  
  
