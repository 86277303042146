body {
    margin: 0;
    padding: 0;
    font-family: "Arial", sans-serif;
    overflow: hidden; 
  }
  
  .scroll-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; 
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .section {
    height: 100vh;
    scroll-snap-align: start;
  }
  .pagination {
    position: fixed;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
  }
  
  .pagination-dot {
    width: 10px;
    height: 10px;
    background: #6e6e6e;
    border-radius: 50%;
    transition: background 0.3s;
    cursor: pointer;
  }
  
  .pagination-dot:hover {
    background: #555;
  }
  
  .pagination-dot.active {
    background: gold;
  }
  
  
  /* starting */
  /* 1st frame */
  .TermsOfServices-container {
    display: flex;
    flex-direction: column; 
   align-items: center;
   padding: 10px;
  }
  
  .TermsOfServices-heading {
    font-family: 'Crimson Pro', serif;
    font-size: 40px;
    color: #0B8BBB;
    margin-bottom: 20px;
    /* Additional styling */
  }
  
  .TermsOfServices-introduction {
    margin-top: 20px;
    /* Other styles for the introduction section container */
  }
  
  .TermsOfServices-subheading {
    font-size: 25px;
    margin-bottom: 10px;
    text-align: justify;
    color: #E8BB2C;
    text-transform: uppercase;
    /* Additional subheading styles */
  }
  
  .TermsOfServices-paragraph {
    font-size: 17px;
    line-height: 1.6;
    color: #000000;
    text-align: justify;
    /* Styles for your paragraph text */
  }
  .TermsOfServices-acceptance {
    margin-top: 20px;
    /* Additional styles specific to the acceptance section if needed */
  }
  .TermsOfServices-description {
    margin-top: 20px;
    /* Additional styles for the description section */
  }
  .TermsOfServices-termination {
    margin-top: 20px;
    /* Additional styles for the termination section if needed */
  }

.TermsOfServices-userObligations {
    margin-top: 20px;
    /* Additional styles for the user obligations section if needed */
  }
  .TermsOfServices-intellectualProperty {
    margin-top: 20px;
    /* Additional styles for the intellectual property rights section if needed */
  }
  .TermsOfServices-disclaimers {
    margin-top: 20px;
    /* Additional styles for the disclaimers and limitations section if needed */
  }
  .TermsOfServices-generalTerms {
    margin-top: 20px;
    /* Additional styles for the general terms section if needed */
  }
  
  .TermsOfServices-list {
    margin-top: 10px;
    margin-left: 20px;
    /* Styles for list indentation, bullet style, etc. */
  }
  
  .TermsOfServices-list li {
    margin-bottom: 10px;
    text-align: justify;
    /* Style each list item for better readability */
  }
  
 .TermsOfServices-Strong{
    text-transform: uppercase;
    font-size: 20px;
 }
 .TermsOfServices-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  }
  
  /* animation */
  
  /* Continuing from previous styles... */

/* Keyframes for a subtle fade-in and up animation */
@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Class for the animation */
  .TermsOfServices-animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Apply the animation class to all section containers with a staggered delay */
  .TermsOfServices-introduction {
    animation-delay: 0.2s;
  }
  
  .TermsOfServices-acceptance {
    animation-delay: 0.4s;
  }
  
  .TermsOfServices-description {
    animation-delay: 0.6s;
  }
  
  .TermsOfServices-userObligations {
    animation-delay: 0.8s;
  }
  
  .TermsOfServices-intellectualProperty {
    animation-delay: 1s;
  }
  
  .TermsOfServices-termination {
    animation-delay: 1.2s;
  }
  
  .TermsOfServices-disclaimers {
    animation-delay: 1.4s;
  }
  
  .TermsOfServices-generalTerms {
    animation-delay: 1.6s;
  }
  
  /* Ensure all animated elements have the animation class */
  .TermsOfServices-introduction,
  .TermsOfServices-acceptance,
  .TermsOfServices-description,
  .TermsOfServices-userObligations,
  .TermsOfServices-intellectualProperty,
  .TermsOfServices-termination,
  .TermsOfServices-disclaimers,
  .TermsOfServices-generalTerms {
    will-change: opacity, transform; /* Optimize the properties that will change during animations */
    animation: fadeInUp 0.8s ease-in-out forwards; /* Apply the animation */
  }
  @media screen and (max-width: 768px) {

    .TermsOfServices-container {
      display: flex;
      flex-direction: column; 
     align-items: center;
     padding: 5px;
    }
    .TermsOfServices-heading {
      font-family: 'Crimson Pro', serif;
      font-size: 24px;
      color: #0B8BBB;
      margin-bottom: 5px;
      /* Additional styling */
    }
    .TermsOfServices-introduction {
      margin-top: 5px;
      
      /* Other styles for the introduction section container */
    }
    .TermsOfServices-subheading {
      font-size: 13px;
      margin-bottom: 1px;
      text-align: center;
      color: #E8BB2C;
      text-transform: uppercase;
      /* Additional subheading styles */
    }
    .TermsOfServices-paragraph {
      font-size: 11px;
      line-height: 1.2;
      color: #000000;
      text-align: justify;
      /* Styles for your paragraph text */
    }
    .TermsOfServices-acceptance {
      margin-top: 5px;
      /* Additional styles specific to the acceptance section if needed */
    }
    .TermsOfServices-description {
      margin-top: 5px;
      /* Additional styles for the description section */
    }
    .TermsOfServices-Strong{
      text-transform: capitalize;
      font-size: 10px;
   }
   .TermsOfServices-list li {
    margin-bottom: 5px;
    text-align: justify;
    font-size: 11px;
    /* Style each list item for better readability */
  }
  .TermsOfServices-list {
    margin-top: 5px;
    margin-left: 15px;
    /* Styles for list indentation, bullet style, etc. */
  }
  .TermsOfServices-termination {
    margin-top: 5px;
    /* Additional styles for the termination section if needed */
  }

.TermsOfServices-userObligations {
    margin-top: 5px;
    /* Additional styles for the user obligations section if needed */
  }
  .TermsOfServices-intellectualProperty {
    margin-top: 5px;
    /* Additional styles for the intellectual property rights section if needed */
  }
  .TermsOfServices-disclaimers {
    margin-top: 5px;
    /* Additional styles for the disclaimers and limitations section if needed */
  }
  .TermsOfServices-generalTerms {
    margin-top: 5px;
    /* Additional styles for the general terms section if needed */
  }
  
  }