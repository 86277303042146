.EWalletAppCaseStudy-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
}

.EWalletAppCaseStudy-container {
    width: 88vw;
    height: 80vh; /* Adjust based on your preference */
    overflow-y: auto;
    margin: 0 auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    box-shadow: 2px 1px 6px black;
}
.EWalletAppCaseStudy-container:hover {
    box-shadow: 4px 2px 12px #E8BB2C;
    
}


.EWalletAppCaseStudy-container::-webkit-scrollbar {
    width: 0; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.EWalletAppCaseStudy-custom-scrollbar {
    position: absolute;
    right: 5%;
    top: 0;
    width: 10px;
    height: 50vh; /* Match the height of .EWalletAppCaseStudy-container */
    background: transparent;
    border-radius: 5px;
}
  
@keyframes EWalletAppCaseStudy-gradient-slide {
    0%, 100% {
      background-position: left bottom;
    }
    50% {
      background-position: left top;
    }
  }
  
  .EWalletAppCaseStudy-custom-thumb {
      width: 10px;
    max-height: 100%;
      background: linear-gradient(to bottom, #03BAA8, #0B8BBB); /* Replace with your desired gradient colors */
      border-radius: 5px;
      position: relative;
      background-size: 100% 200%; /* Double the background size's height for the sliding effect */
      animation: EWalletAppCaseStudy-gradient-slide 3s linear infinite;
      transition: transform 0.1s;
  }
  
  .EWalletAppCaseStudy-heading {
    font-size: 30px; /* Or any other size you prefer */

    color: #ffffff; /* Adjust the color to fit your theme */
    margin-bottom: 20px; /* Add some space below the heading */
    /* Additional styling if needed */
    background-color: #0B8BBB;
    width: max-content;
    padding: 5px;
    margin-left: 20%;
    border-radius: 5px;
    margin-top: 5%;
    
    opacity: 0;
    transform: translateY(-10px); /* Start above its starting position */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* For performance */
}
.EWalletAppCaseStudy-image {
    display: block; /* Images are inline by default */
    width: 100%; /* Makes the image responsive */
    max-width: 550px; /* Adjust the max-width as necessary */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto 5px; /* Center the image and add some space below */
    border-radius: 4px; /* Optional: if you want rounded corners */
    box-shadow: 2px 4px 6px #E7B92B;
   
    opacity: 0; /* Start with the image invisible */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* Optimizes animations */
    margin-top: 0px;
    margin-bottom: 2%;
}

.EWalletAppCaseStudy-intro {
    font-size: 20px; /* Adjust the font size as needed */
    color: #000000; /* Adjust the text color to fit your design */
    margin: 16px auto; /* Add top and bottom margin for spacing */
    max-width: 780px; /* Max width of the text block for readability */
    text-align: center; /* Center align the text if that matches your design */
    font-weight: 900;
    opacity: 0; /* Start with the image invisible */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* Optimizes animations */
}
.EWalletAppCaseStudy-intro.animate {
    /* State when scrolled into view */
    opacity: 1;
    transform: translateY(-10px);
}
.EWalletAppCaseStudy-subheading {
    font-size: 20px;
    color: #E6B62B;
    margin-top: 24px;
    text-align: center;
    text-transform: uppercase;
}

.EWalletAppCaseStudy-details {
    font-size: 18px;
    color: #333;
    margin: 20px auto;
    max-width: 750px;
    line-height: 1.6; /* Improves readability */
    opacity: 0; /* Start with the image invisible */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* Optimizes animations */
}
.EWalletAppCaseStudy-details.animate {
    /* State when scrolled into view */
    opacity: 1;
    transform: translateY(-10px);
}
.EWalletAppCaseStudy-details p {
    margin-bottom: 10px;
}

.EWalletAppCaseStudy-details p strong {
    font-weight: bold; /* Makes the titles bold */
}
.EWalletAppCaseStudy-brand {
    font-size: 18px;
    color: #333;
    margin: 20px auto;
    max-width: 750px; /* Ensures the text is not too wide for readability */
    line-height: 1.6; /* Improves readability */
    opacity: 0; /* Start with the image invisible */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* Optimizes animations */
}
.EWalletAppCaseStudy-brand.animate {
    /* State when scrolled into view */
    opacity: 1;
    transform: translateY(-10px);
}

.EWalletAppCaseStudy-brand p {
    margin-bottom: 10px; /* Adds spacing between paragraphs if needed */
}
.EWalletAppCaseStudy-objective {
    font-size: 16px;
    color: #333;
    margin: 20px auto;
    max-width: 750px; /* Consistent with other sections for readability */
    line-height: 1.6;
    opacity: 0; /* Start with the image invisible */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* Optimizes animations */
}
.EWalletAppCaseStudy-objective.animate {
    /* State when scrolled into view */
    opacity: 1;
    transform: translateY(-10px);
}

.EWalletAppCaseStudy-objective ul {
    list-style-type: lower-roman; /* Or choose none if you prefer a cleaner look */
    padding-left: 20px; /* Proper indentation for the list */
}

.EWalletAppCaseStudy-objective li {
    margin-bottom: 10px; /* Spacing between list items */
}
.EWalletAppCaseStudy-challenges {
    font-size: 16px;
    color: #333;
    margin: 20px auto;
    max-width: 750px;
    line-height: 1.6;
    opacity: 0; /* Start with the image invisible */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* Optimizes animations */
}
.EWalletAppCaseStudy-challenges.animate {
    /* State when scrolled into view */
    opacity: 1;
    transform: translateY(-10px);
}
.EWalletAppCaseStudy-KeyFeatures {
    font-size: 16px;
    color: #333;
    margin: 20px auto;
    max-width: 750px;
    line-height: 1.6;
    opacity: 0; /* Start with the image invisible */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* Optimizes animations */
}
.EWalletAppCaseStudy-KeyFeatures ul {
    list-style-type: lower-roman; /* Or choose none if you prefer a cleaner look */
    padding-left: 20px; /* Proper indentation for the list */
}

.EWalletAppCaseStudy-KeyFeatures.animate {
    /* State when scrolled into view */
    opacity: 1;
    transform: translateY(-10px);
}

.EWalletAppCaseStudy-challenges ul {
    list-style-type: lower-roman; /* Style the list items with bullets */
    padding-left: 20px; /* Indentation for list items */
}
.EWalletAppCaseStudy-solution ul {
    list-style-type: lower-roman; /* Style the list items with bullets */
    padding-left: 20px; /* Indentation for list items */
}

.EWalletAppCaseStudy-challenges li {
    margin-bottom: 10px; /* Space between list items for better readability */
}
.EWalletAppCaseStudy-solution {
    font-size: 16px;
    color: #000000;
    margin: 20px auto;
    max-width: 750px; /* Consistent with other sections */
    line-height: 1.6;
    opacity: 0; /* Start with the image invisible */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* Optimizes animations */
}
.EWalletAppCaseStudy-solution.animate {
    /* State when scrolled into view */
    
    opacity: 1;
    transform: translateY(-5px);
}
.EWalletAppCaseStudy-solution p {
    margin-bottom: 10px; /* Space between paragraphs */
}

.EWalletAppCaseStudy-solution strong {
    font-weight: bold; /* Highlights the solution titles */
    display: block; /* Ensures the checkmark and title start on a new line */
}
/* ... existing styles ... */

.EWalletAppCaseStudy-solutionImage {
    width: 100%; /* Makes the image responsive to the container width */
    max-width: 400px; /* Adjust max-width as needed */
    height: auto; /* Keep the image's aspect ratio */
    display: block; /* Images are inline by default */
    margin: 20px auto; /* Centers the image and adds margin above and below */
    border-radius: 4px; /* Optional for rounded corners */
    opacity: 0; /* Start with the image invisible */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform; /* Optimizes animations */
    margin-top: 60px;
}

.EWalletAppCaseStudy-solutionImage.animate {
    opacity: 1; /* Fully visible when animated */
    transform: translateY(-10px); /* Adjust as needed */
}
.EWalletAppCaseStudy-heading.animate {
    /* State when scrolled into view */
    opacity: 1;
    transform: translateY(-30px);
}
.EWalletAppCaseStudy-image.animate {
    /* State when scrolled into view */
    opacity: 1;
    transform: translateY(-10px);
}
/* EWalletAppCaseStudyintroRef */
.EWalletAppCaseStudy-strong{
    text-transform: uppercase;
}
@media screen and (max-width: 768px){
    .EWalletAppCaseStudy-heading {
        font-size: 8px; /* Or any other size you prefer */
    
        color: #ffffff; /* Adjust the color to fit your theme */
        margin-bottom: 20px; /* Add some space below the heading */
        /* Additional styling if needed */
        background-color: #0B8BBB;
        width: max-content;
        padding: 5px;
        margin-left: 2%;
        border-radius: 5px;
        margin-top: 1%;
        
        opacity: 0;
        transform: translateY(0px); /* Start above its starting position */
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        will-change: opacity, transform; /* For performance */
    }
    .EWalletAppCaseStudy-heading.animate {
        /* State when scrolled into view */
        opacity: 1;
        transform: translateY(0px);
    }
    .EWalletAppCaseStudy-image {
        display: block; /* Images are inline by default */
        width: 100%; /* Makes the image responsive */
        max-width: 250px; /* Adjust the max-width as necessary */
        height: auto; /* Maintain aspect ratio */
        margin: 0px; /* Center the image and add some space below */
       margin-left: 12%;
        border-radius: 4px; /* Optional: if you want rounded corners */
        box-shadow: 2px 4px 6px #E7B92B;
       
        opacity: 0; /* Start with the image invisible */
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        will-change: opacity, transform; /* Optimizes animations */
        margin-top: 10px;
    }
    .EWalletAppCaseStudy-intro {
        font-size: 12px; /* Adjust the font size as needed */
        color: #000000; /* Adjust the text color to fit your design */
        margin: 16px auto; /* Add top and bottom margin for spacing */
        max-width: auto ; /* Max width of the text block for readability */
        text-align: center; /* Center align the text if that matches your design */
        font-weight: 900;
        opacity: 0; /* Start with the image invisible */
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        will-change: opacity, transform; /* Optimizes animations */
    }
    .EWalletAppCaseStudy-subheading {
        font-size: 22px;
        color: #E6B62B;
        margin-top: 10px;
        text-align: center;
        text-transform: uppercase;
    }
    .EWalletAppCaseStudy-details {
        font-size: 18px;
        color: #333;
        margin: 20px auto;
        max-width: 750px;
        line-height: 1.6; /* Improves readability */
        opacity: 0; /* Start with the image invisible */
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        will-change: opacity, transform; /* Optimizes animations */
    }
   
    .ReactFitnessTrackerCaseStudy-details p {
        margin-bottom: 5px;
    }
    

   

}