.HeaderMain{
    position: relative;
}
.header-container {
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #ffffff;
    padding: 5px;
    gap: 3%;
    margin-bottom: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    top: 1;
    width: 100%;
    top: 35px;
    
    
}

.company-logo {
    height: 75px;  
    width: auto;
}
.company-slogan {
    font-size: 28px;
    margin: 0; 
    font-weight: 700;
    margin-left: 0%;
}
.achieve-color {
    color: #E8BB2D;
}

.more-color {
    color: #0B8ABA;
}
/* nav bar style */
/* ... Existing styles ... */
.SLogan-link-BC{
    text-decoration: none;
}
.navbar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    
    gap: 20px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.navbar li {
    position: relative;
    cursor: pointer;
    font-size: 20px;
    
}

.navbar a {
    text-decoration: none;
    color: inherit;
    padding: 5px 10px;
    position: relative;
    overflow: hidden; 
    transition: color 0.3s;
}

.navbar a::after {
    content: '';
    position: absolute;
    width: 0%;  
    height: 2px;
    bottom: 0;
    left: 50%;  
    background: #E8BB2D;
    transition: all 0.3s; 
    transform: translateX(-50%);  
}

.navbar a:hover {
    color: #E8BB2D;
}

.navbar a:hover::after {
    width: 100%;  
}
/* If you have a dropdown content, ensure they don't inherit the same hover effects as the main navbar links */

/* dropdown */

/* contact info and socail media */
.navbar a.active {
    color: rgb(232, 187, 44);
  }
  .active {
    color: #E8BB2D;
}
.mobile-nav {
    width: 30px;
    height: 22.5px;
    position: relative;
    cursor: pointer;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    margin-left: 10%;
  }
  .bar {
    display: block;
    position: absolute;
    height: 4px;
    width: 90%;
    background: #0B8BBB;  /* Adjust this to your desired color */
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .30s ease-in-out;
  }
  .bar1 {
    top: 0px;
    transform-origin: left center;
  }
  
  .bar2 {
    top: 9.5px;
    transform-origin: left center;
  }
  
  .bar3 {
    top: 19px;
    transform-origin: left center;
  }
  /* Styles for the X state, when menu is opened */
  .mobile-nav.open .bar1 {
    transform: rotate(55deg);
    top: -1px;
    left: 7px;
  }
  
  .mobile-nav.open .bar2 {
    width: 0%;  /* hide this line */
    opacity: 0;
  }
  
  .mobile-nav.open .bar3 {
    transform: rotate(-55deg);
    top: 23px;
    left: 7px;
  }

.mobile-nav-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.mobile-nav-menu.open {
    display: block;
}

.mobile-nav-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.mobile-nav-menu li {
    border-bottom: 1px solid #ddd;
}

.mobile-nav-menu a {
    display: block;
    text-decoration: none;
    padding: 10px 20px;
    text-align: center;
    color: #0B8ABA;
}


/* logo animation */
/* Base styles for logo container */
/* Base styles for logo container */
.logo-container {
    position: relative;
    cursor: pointer;
    
    
}

/* Base styles for company logo */
.company-logo {
    transition: transform 0.5s ease-in-out;
    /* Additional base styles for the logo */
}

/* Initially hide the coding visuals text */
.coding-visuals {
    position: absolute;
    bottom: 0; /* Align with the bottom of the container */
    left: 0;
    transform: translateY(100%); /* Initially moved down out of view */
    opacity: 0; /* Make it fully transparent */
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    /* Additional base styles for the text */
    margin-top: 10%;
    
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    background-color: rgb(255, 255, 255);
    width: max-content;
    padding: 2%;
   
}


/* Hover animations */
.logo-container:hover .company-logo {
    transform: scale(0.5) translate(100%, -40%); 
}


.logo-container:hover .coding-visuals {
    transform: translateY(-35%); /* Bring text up to the bottom of the logo */
    opacity: 1; /* Make text fully visible */
    
}

.coding {
    color: #E8BB2D;
    /* Additional styles if needed */
}

/* Styles for "Visuals" */
.visuals {
    color: #0B8BBB;
    /* Additional styles if needed */
}