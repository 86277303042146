body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  overflow: hidden; 
}

.scroll-container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none; 
}

.scroll-container::-webkit-scrollbar {
  display: none;
}
.section {
  height: 100vh;
  scroll-snap-align: start;
}
.pagination {
  position: fixed;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.pagination-dot {
  width: 10px;
  height: 10px;
  background: #6e6e6e;
  border-radius: 50%;
  transition: background 0.3s;
  cursor: pointer;
}

.pagination-dot:hover {
  background: #555;
}

.pagination-dot.active {
  background: gold;
}



/* starting */
.Insights-cardContainer {
  display: flex;

  
}

.Insights-cardFirst {
  width: 50vw;
  height: auto;
  background-color: #0B8BBB;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  margin-left: 20px;
  margin-top: 10px;
  
}
.card2ndand3rd{
  display: flex;
  justify-content: end;
  gap: 2%  ;
}
.Insights-cardSecond   {
  width: 35vw;
  height: auto;
  background-color: #0B8BBB;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  margin-top: 10px;
  margin-left: 1%;
  
}
.Insights-cardThird {
  width: 30vw;
  height: auto;
  background-color: #0B8BBB;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  margin-right: 20px;
  margin-top: 10px;
}

/* Optional: Basic hover effect */
.Insights-cardFirst:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
  transition: all 0.2s;
}
.Insights-cardSecond:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
  transition: all 0.2s;
}
.Insights-cardThird:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
  transition: all 0.2s;
}

.Insights-cardFirst h2, .Insights-cardSecond h2, .Insights-cardThird h2 {
  
  color: #ffffff;  /* Set text color to white for better readability on the blue background */
  font-size: 20px;  /* Adjust size if needed */
  text-align: center;  /* Center align the text */
  padding: 5px;  /* Space around the heading */
  /* Add any other desired styles */
}
.Insights-cardImage {
  max-width: 100%;  /* Ensure the image doesn't overflow the card */
  height: auto;    /* Maintain the image's aspect ratio */
  display: block;  /* Remove any alignment issues */
  margin: 10px auto;
  margin-top: 30px;
  border-radius: 3px;
  
}
#InsightssecondcardIMG{
  height: 243px;
}

.Insights-clickableText {
  display: inline-block;
  margin-top: 65px;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #0B8BBB;  /* To make the text white */
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;  /* Remove underline */
  font-size: 1.2rem;  /* Slightly larger font */
  transition: all 0.2s; /* Smooth transition */
  position: relative;  /* To position the arrow inside it on hover */
  
}

/* Hover effect */
.Insights-clickableText:hover {
  padding-right: 50px;  /* Increase padding to make space for the arrow */
}

/* Add arrow on hover */
.Insights-clickableText:hover::after {
  content: '➡️';  /* Arrow symbol */
  position: absolute;
  right: 15px;  /* Adjust this to position the arrow as you desire */
  top: 50%;
  transform: translateY(-50%);  /* Center it vertically */
  font-size: 1.2rem;  /* Match font size of the button */
 
}
/* 2nd card button */
.Insights-clickableTextSecond {
  display: inline-block;
  margin-top: 65px;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #0B8BBB;  /* To make the text white */
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;  /* Remove underline */
  font-size: 1.2rem;  /* Slightly larger font */
  transition: all 0.2s; /* Smooth transition */
  position: relative;  /* To position the arrow inside it on hover */
}

/* Hover effect */
.Insights-clickableTextSecond:hover {
  padding-right: 50px;  /* Increase padding to make space for the arrow */
}

/* Add arrow on hover */
.Insights-clickableTextSecond:hover::after {
  content: '🔓';  /* Arrow symbol */
  position: absolute;
  right: 15px;  /* Adjust this to position the arrow as you desire */
  top: 50%;
  transform: translateY(-50%);  /* Center it vertically */
  font-size: 1.2rem;  /* Match font size of the button */
 
}

/* 3rd card button */

.Insights-clickableTextThird {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #0B8BBB;  /* To make the text white */
  font-weight: bold;
  border-radius: 8px;
  text-decoration: none;  /* Remove underline */
  font-size: 1.2rem;  /* Slightly larger font */
  transition: all 0.2s; /* Smooth transition */
  position: relative;  /* To position the arrow inside it on hover */
}

/* Hover effect */
.Insights-clickableTextThird:hover {
  padding-right: 50px;  /* Increase padding to make space for the arrow */
}

/* Add arrow on hover */
.Insights-clickableTextThird:hover::after {
  content: '📰';  /* Arrow symbol */
  position: absolute;
  right: 15px;  /* Adjust this to position the arrow as you desire */
  top: 50%;
  transform: translateY(-50%);  /* Center it vertically */
  font-size: 1.2rem;  /* Match font size of the button */
 
}


