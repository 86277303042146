.fixed-text, .changing-text, .subtext {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
 
  font-family: 'Poppins', sans-serif;
    overflow: hidden; 
  }
  
  .scroll-container {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; 
  }
  
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .section {
    height: 100vh;
    scroll-snap-align: start;
    
    
  }
  
  .home-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%; /* takes up the full height of its container */
}
  
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  
}

  .content {
    color: rgb(0, 0, 0);
    font-size: 40px;
    font-weight: 900;
    margin-left: 10px;
    display: flex;
    align-items: center; /* vertically aligns the content */
    height: auto; 
    grid-row: 1;
    
  }
  .content-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: start;
    grid-template-rows: auto 1fr; 
}

  .changing-text {
    color: rgb(230, 182, 42);
    font-size: 50px;
    width: 200px; /* adjust this to be more than the width of the longest word */
    overflow: hidden; 
    text-align: left; 
    height: 60px;
    
    

    
  }
  .fixed-text, .changing-text {
    line-height: 1; /* ensures consistent height */
    vertical-align: middle; /* centers text vertically */
    margin: 0;/* ... other styles ... */
}
  .text-container {
    display: flex;
    flex-wrap: nowrap;
}

  .subtext {
    color: rgb(255, 255, 255);
    font-size: 40px;
    margin-top: 10px;
    grid-row: 2;
    margin-bottom: 100px;
    
  }
  
  .content,
  .subtext {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
  
  .subtext span {
    color: rgb(230, 182, 42);
    font-size: 50px;
  }
  
  .pagination {
    position: fixed;
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
  }
  
  .pagination-dot {
    width: 10px;
    height: 10px;
    background: #6e6e6e;
    border-radius: 50%;
    transition: background 0.3s;
    cursor: pointer;
  }
  
  .pagination-dot:hover {
    background: #555;
  }
  
  .pagination-dot.active {
    background: gold;
  }
  
  .reasons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
    background-color: #ffffff; 
   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    margin: 10px; 
    max-width: 100%; 
    margin-left: auto;
    margin-right: auto;
  }
  
  .reasons-container h2 {
    font-size: 34px;
    color: #E8BB2C;
    margin-bottom: 20px;
    font-weight: bold;
    border-bottom: 2px solid #333; 
    padding-bottom: 10px;
  }
  .fixed-text{
    margin-right: 20px;
    color: #ffffff;
    margin-left: 85px;
  }


  @keyframes drawAndFill {
    50% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    100% {
      fill: #0B8BBB; 
    }
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    
  }
  
  .draw-path {
    fill: none;
    stroke: #0B8BBB; 
    stroke-width: 2.5;
    stroke-dasharray: 2000; 
    stroke-dashoffset: 2000;
    animation: drawAndFill 8s ease-in-out infinite; 
  }
  .client-count{
    display: flex;

  }
  .mobileorderchange{
    margin-top: 4%;
  }
  .Clients {
    font-size: 30px; 
    color: #0B8BBB; 
    font-weight: bold;
    margin-top: 10px;
    
    
  }
  
  .images-container {
    display: flex;
    gap: 4%;
  }
  
  .image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-section img {
    width: 450px; 
    height: auto; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-section h3 {
    font-size: 18px;
    margin-top: 10px;
    color: #333;
    text-align: center;
  }
  .detailed-text {
    font-size: 30px; /* Adjust based on your preference */
    color: #03BAA8; /* Adjust color as needed */
    margin-top: 30px; /* Provides a bit of spacing */
  }
  @keyframes drawAndFillDownArrow {
    50% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    100% {
      fill: #03BAA8; 
    }
  }
  
  .down-arrow .drawing-path {
    
    fill: none;
    stroke: #03BAA8; /* Different color for the down arrow icon */
    stroke-width: 2.5;
    stroke-dasharray: 1500; /* You can modify this for a different drawing effect */
    stroke-dashoffset: 1500;
    animation: drawAndFillDownArrow 5s ease-in-out infinite; /* Faster drawing speed for distinction */
  }
  
  /* Animation for SEO1 */
  @keyframes fadeInUp {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
  }
  
  @keyframes fadeInDown {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
  }
  
  .fade-in-up {
    animation: fadeInUp 1s;
  }
  
  .fade-in-down {
    animation: fadeInDown 1s;
  }
  .flex-img-2ndframeHome{
    display: flex;
  }
  /* 3rd frame */
  .device-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    gap: 140px; /* spacing between the two laptop screens */
  }
  
  .laptop-screen {
    width: 600px; /* subtracting half of the gap to maintain the gap properly between devices */
    height: 500px; /* 16:9 aspect ratio, which is common for laptop screens */
    border: 3px solid #000; /* border representing laptop screen edges */
    border-radius: 15px; 
    background: #fff; /* representing the laptop screen; you can also use an image or gradient */
    position: relative;
    margin-top: 30px;
   
    
  }
  
  .laptop-image {
    width: 100%;       /* Ensure the image takes up the full width of the .laptop-screen */
    height: 100%;      /* Ensure the image takes up the full height of the .laptop-screen */
    object-fit: cover; /* This will make sure the image scales nicely inside its container */
    border-radius: 12px; /* To match the rounded corners of .laptop-screen*/
    display: block;    /* To remove any potential spacing issues */
  }
  .Home-website-name {
    
    
    color: #ffffff;
    background-color: #0B8BBB;
    padding: 5px 10px;
    border-radius: 20px;
    width: 600px;  
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 15px; 
}
  .Home-case-study-name {
    
         
              
    color: #ffffff;
    background-color: #0B8BBB;
    padding: 5px 10px;
    border-radius: 20px;
    width: 600px;  
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 15px;
  }
  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .laptop-screen:first-child {
    animation: slideFromLeft 1s forwards;
  }
  
  .laptop-screen:nth-child(2) {
    animation: slideFromRight 1s forwards;
  }
  /* 4th frame */
  /* 4th frame */
  
  .other-section {
    height: 100vh;
    scroll-snap-align: start;
  }
  
  .other-device-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 140px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  
  .other-laptop-screen {
    width: 600px;
    height: 500px; 
    border: 3px solid #000;
    border-radius: 15px; 
    background: #fff;
    position: relative;
    margin-top: 30px;
  }
  
  .laptop-image {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    border-radius: 12px; 
    display: block; 
  }
  
  .Home-other-website-name {
    
    color: #ffffff;
    background-color: #0B8BBB;
    padding: 5px 10px;
    border-radius: 20px;
    width: 600px;  
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 15px;
  }
  
  .Home-other-case-study-name {
    
    color: #ffffff;
    background-color: #0B8BBB;
    padding: 5px 10px;
    border-radius: 20px;
    width: 600px;  
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 15px;
  }
  
  @keyframes otherSlideFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes otherSlideFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .other-laptop-screen:first-child {
    animation: slideFromLeft 1s forwards;
  }
  
  .other-laptop-screen:nth-child(2) {
    animation: slideFromRight 1s forwards;
  }
  /* 5th frame */
  
  /* Footer Container Styles */
  .fifth-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* this will align items to the start of the container */
    height: auto; /* adjust this if necessary */
    min-height: 100px; /* or some appropriate value that suits your design */
}
.Home-Revise-footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  background-color: #0B8BBB;
  width: 100%;
  align-self: flex-start;
  margin-bottom: 20px;
  height:50px ;
  
  
}

.Home-footer-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Home-footer-item a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}

.Home-footer-item a:hover {
  color: gold;
}
.Home-footer-item svg {
  fill: white;
  transition: transform 0.3s, fill 0.3s;
}
.Home-footer-item:hover svg {
  transform: scale(1.2); /* This will enlarge the SVG by 20% */
  fill: gold; /* This changes the color to gold */
}

.Home-footer-item a:hover + svg {
  transform: scale(1.2);
  fill: gold;
}
.Home-secondary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 10%;
  background-color: #0B8BBB;
  width: 100%;
  margin-bottom: 20px;

}

.Home-mission, .Home-solution {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.Home-mission h3, .Home-solution h3 {
  color: gold;
}

.Home-mission p {
  color: #ffffff;
  text-align: center;
  margin-bottom: 40px;
  font-size: 18px;
}

.Home-solution ul {
  list-style-type: none;
  padding: 0;
  color: #ffffff;
}

.Home-solution li {
  margin-bottom: 10px;
}
.Home-copyright-section {
  padding: 20px 0;
  text-align: center;
  background-color: #0B8BBB;  /* A light gray background */
  font-size: 14px;
  border-top: 1px solid #e0e0e0;
  width: 100%;
  margin-bottom: 180px;
  margin-top: 10px;
}

.Home-copyright-section p {
  margin: 0;
  color: #ffffff;  /* Slightly dark text color */
  font-size: 18px;
}

.Home-copyright-section a {
  color: #ffffff;  /* Classic link color */
  text-decoration: none;
  margin: 0 5px;
}

.Home-copyright-section a:hover {
  text-decoration: underline;
  color: gold;
}
.HomeFooterSocialMediaLinks{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px ;
}
.HomeFooterSocialMediaLinks h3{
  color: gold;
}
.HomeFooterSocialMediaLinks svg{
  fill: white;
  
}
.HomeFooterSocialMediaLinks svg:hover{
  fill: black;
}
@media   (max-width: 768px) {
  
            }